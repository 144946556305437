/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import Chart from "chart.js/auto";
import { handleParticularFeePayerData } from "utils/helpers";
import { useStore } from "../store/index";

const OnchainChart: React.FC = () => {
  const [overallTranscationCount, setOverallTransactionCount] = useState<any>();
  const { particularFeepayeraddress, totalUsers, setDappUsersPercentage } =
    useStore();

  useEffect(() => {
    async function fetchData() {
      try {
        const feePayerResponse = await handleParticularFeePayerData(
          particularFeepayeraddress
        );
        const percentage =
          (feePayerResponse?.overall_users_till_now / totalUsers) * 100;
          setDappUsersPercentage(percentage.toFixed(2))

        setOverallTransactionCount(feePayerResponse?.overall_users_till_now);
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, []);

  const chartRef = useRef<HTMLCanvasElement | null>(null);
  const [chart, setChart] = useState<Chart<
    "doughnut",
    number[],
    string
  > | null>(null);
  useEffect(() => {
    if (chart) {
      chart.destroy(); // Destroy existing chart instance if it exists
    }
    if (chartRef.current) {
      const ctx = chartRef.current.getContext("2d");
      if (ctx) {
        const newChartInstance: Chart<"doughnut", number[], string> = new Chart(
          ctx,
          {
            type: "doughnut",
            data: {
              datasets: [
                {
                  data: [totalUsers, overallTranscationCount],
                  backgroundColor: ["#FDB022", "#0E9384", "#00F9A9"],
                  borderColor: ["#FDB022", "#0E9384", "#00F9A9"],
                  borderWidth: 1,
                },
              ],
            },
            options: {
              responsive: true,
              maintainAspectRatio: false,
              plugins: {
                legend: {
                  display: false,
                },
              },
            },
          }
        );
        setChart(newChartInstance);
      }
    }
  }, [totalUsers, overallTranscationCount]);

  return (
    <div style={{ width: "80px", height: "70px" }}>
      <canvas ref={chartRef}></canvas>
    </div>
  );
};

export default OnchainChart;
