"use client";
import React, { useState } from "react";
import Admin from "../assets/icons/Admin-footer.svg";
import FreepAyer from "../assets/icons/freepayer-footer.svg";
import Functions from "../assets/icons/functions-footer.svg";
import transcation from "../assets/icons/transcation-footer.svg";
import Whitelist from "../assets/icons/whitelist-footer.svg";
import { useStore } from "store";

const Footer = () => {
  const [account, setAccount] = useState(false);
  const [freepayer, setFreepayer] = useState(false);
  const [functions, setFunctions] = useState(false);
  const [transcations, setTranscations] = useState(false);
  const [whitelist, setWhitelist] = useState(false);
  const { checkIsAdmin } = useStore();
  const handleAcccount = () => {
    setAccount(true);
    setFreepayer(false);
    setFunctions(false);
    setTranscations(false);
    setWhitelist(false);
  };
  const handleFreepayer = () => {
    setAccount(false);
    setFreepayer(true);
    setFunctions(false);
    setTranscations(false);
    setWhitelist(false);
  };

  const handlefunctions = () => {
    setAccount(false);
    setFreepayer(false);
    setFunctions(true);
    setTranscations(false);
    setWhitelist(false);
  };
  const handleTranscations = () => {
    setAccount(false);
    setFreepayer(false);
    setFunctions(false);
    setTranscations(true);
    setWhitelist(false);
  };
  const handleWhitelists = () => {
    setAccount(false);
    setFreepayer(false);
    setFunctions(false);
    setTranscations(false);
    setWhitelist(true);
  };
  return (
    <div>
      <div className=" w-screen xxl:hidden xl:hidden sxl:hidden bxl:hidden lg:hidden md:inline-flex sm:inline-flex xd:inline-flex !bg-[#0C0C0D] fixed bottom-0">
        {checkIsAdmin ? (
          <>
            <div className="h-24 z-[1] !bg-[#111213] mt-40 !fixed !bottom-0 flex flex-row  items-start justify-evenly px-[0.5rem] w-full !font-inter ml-0 xxl:hidden bxl:hidden xl:hidden sxl:hidden lg:hidden md:flex sm:flex xd:flex">
              <div className="flex flex-row  items-start justify-evenly border-t-[#A5A5A6] dark:border-t-[#A5A5A6] border-t-[1px]  w-full">
                <div className=" py-6 flex flex-col justify-center items-center gap-[0.5rem] text-[#A5A5A6] dark:text-[#777879] font-[400]">
                  <img src={Admin} alt="/" />
                  <div>Admin</div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="h-24 z-[1] !bg-[#111213] mt-40 !fixed !bottom-0 flex flex-row  items-start justify-evenly px-[0.5rem] w-full !font-inter ml-0 xxl:hidden bxl:hidden xl:hidden sxl:hidden lg:hidden md:flex sm:flex xd:flex">
              <div className="flex flex-row  items-start justify-evenly border-t-[#A5A5A6] dark:border-t-[#A5A5A6] border-t-[1px]  w-full">
                <div className=" py-6 flex flex-col justify-center items-center gap-[0.5rem] text-[#A5A5A6] dark:text-[#777879] font-[400]">
                  <img src={Admin} alt="/" />
                  <div>Account</div>
                </div>
              </div>
              <div className="flex flex-row  items-start justify-evenly border-t-[#A5A5A6] dark:border-t-[#A5A5A6] border-t-[1px]  w-full">
                <div className=" py-6 flex flex-col justify-center items-center gap-[0.5rem] text-[#A5A5A6] dark:text-[#777879] font-[400]">
                  <img src={FreepAyer} alt="/" />
                  <div>FeePayer</div>
                </div>
              </div>
              <div className="flex flex-row  items-start justify-evenly border-t-[#A5A5A6] dark:border-t-[#A5A5A6] border-t-[1px]  w-full">
                <div className=" py-6 flex flex-col justify-center items-center gap-[0.5rem] text-[#A5A5A6] dark:text-[#777879] font-[400]">
                  <img src={Functions} alt="/" />
                  <div>Functions</div>
                </div>
              </div>
              <div className="flex flex-row  items-start justify-evenly border-t-[#A5A5A6] dark:border-t-[#A5A5A6] border-t-[1px]  w-full">
                <div className=" py-6 flex flex-col justify-center items-center gap-[0.5rem] text-[#A5A5A6] dark:text-[#777879] font-[400]">
                  <img src={transcation} alt="/" />
                  <div>Transaction</div>
                </div>
              </div>
              <div className="flex flex-row  items-start justify-evenly border-t-[#A5A5A6] dark:border-t-[#A5A5A6] border-t-[1px]  w-full">
                <div className=" py-6 flex flex-col justify-center items-center gap-[0.5rem] text-[#A5A5A6] dark:text-[#777879] font-[400]">
                  <img src={Whitelist} alt="/" />
                  <div>Whitelist</div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Footer;
