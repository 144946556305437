import React, { useEffect, useRef, useState } from 'react';
import Kana from "../assets/icons/kana_logo.svg";
import Menu from '../assets/icons/menu.svg'
import Aptos from '../assets/icons/AptosLogo.svg';
import ImprovedUx from '../assets/icons/improvedUx.svg';
// import CustomizedApp from '../assets/icons/customizedApp.svg';
import GrantBg from '../assets/icons/grant.png';
import X from '../assets/icons/X.svg';
import Shield from '../assets/icons/shield.png';
import Lightning from '../assets/icons/lightning.png';
import Wallet from '../assets/icons/wallet.png';
import Coins from '../assets/icons/coins-stacked.png';
import Marquee from "react-fast-marquee";
import { useWallet } from "@aptos-labs/wallet-adapter-react";
import { useStore } from "store";

const Grant = () => {
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const container = containerRef.current;

    if (container) {
      const animationDuration = 13; // seconds
      const distanceToTravel = container.scrollWidth - container.clientWidth;
      const animationTime = (distanceToTravel / container.scrollWidth) * animationDuration;

      container.style.animationDuration = `${animationTime}s`;
    }
  }, []);
  const [isOpenSidebar, setIsOpenSidebar] = useState(false);
  const handleSidebar = () => {
    setIsOpenSidebar(!isOpenSidebar);
  }
  const { connected, network, disconnect, account } = useWallet();
  const {
    iswalletConnect,
    updateWalletConnect,
    updateSignature,
    walletNetwork,
    updateFeePayerBalance,
    signature,
    feePayerBalance,
    feePayerAddresses,
    updateFeePayerAddresses,
    publicKey,
    address,
    updateApiKey,
    apikey,
    details,
    setDetails,
    updateDappDetails,
    updateWhitelisted,
    updateAllowAllScripts,
    updateAllowAllFunctions,
    checkIsAdmin,
    setCheckIsAdmin,
  } = useStore();
  const connectToInstalledWallet = async () => {
    if (connected) {
      disconnect();
      updateSignature("");
      setDetails([]);
    } else {
      updateWalletConnect(true);
    }
    updateWalletConnect(true);
  };

  return (
    <div className="   w-full h-full flex flex-col justify-start items-start  ">
      <div className="p-[24px]  !bg-[#0C0C0D] h-[5.5rem] fixed top-0  z-[2]  backdrop-blur-[4rem] flex flex-row justify-between items-center w-full">
        <img src={Kana} alt="Kana" />
        <div className="flex flex-row items-center gap-[1.5rem] justify-center   w-auto   ">
          <a
            href="https://docs.kanalabs.io/paymaster-service/kana-paymaster-for-aptos"
            target="_blank"
          >
            <div className=" cursor-pointer xxl:flex bxl:flex xl:flex sxl:flex lg:flex md:hidden sm:hidden xd:hidden   text-[#2ED3B7] text-[1rem] font-manrope font-[800] ">
              Documentation
            </div>
          </a>
          <a href="/dashboard">
            <div className=" cursor-pointer  xxl:flex bxl:flex xl:flex sxl:flex lg:flex md:hidden sm:hidden xd:hidden  text-[#2ED3B7] text-[1rem] font-manrope font-[800] ">
              Dashboard
            </div>
          </a>
          <img
            src={Menu}
            className="xxl:hidden bxl:hidden xl:hidden sxl:hidden lg:hidden md:flex sm:flex xd:flex mr-3"
            alt=""
            onClick={handleSidebar}
          />
        </div>
      </div>
      {isOpenSidebar && (
        <div className="fixed z-[2] p-[2rem] xxl:hidden bxl:hidden xl:hidden sxl:hidden lg:hidden md:flex sm:flex xd:flex  flex flex-col justify-start items-start gap-[3rem] w-full h-full left-0 right-0 bottom-0 top-[5.5rem] backdrop-blur-[4rem] ">
          <a
            href="https://docs.kanalabs.io/paymaster-service/kana-paymaster-for-aptos"
            target="_blank"
          >
            <div className="    text-[#2ED3B7] text-[1rem] font-manrope font-[800] ">
              Documentation
            </div>
          </a>
          <a href="/dashboard">
            <div className="    text-[#2ED3B7] text-[1rem] font-manrope font-[800] ">
              Dashboard
            </div>
          </a>
          <div
            onClick={() => connectToInstalledWallet()}
            className="    text-[#2ED3B7] text-[1rem] font-manrope font-[800] "
          >
            Connect Wallet
          </div>
        </div>
      )}
      <div className="flex pt-[140px]  xxl:flex-row bxl:flex-row xl:flex-row sxl:flex-row lg:flex-row md:flex-col sm:flex-col xd:flex-col  justify-start items-start w-full   ">
        <div className="     xxl:w-[43%] bxl:w-[43%] xl:w-[43%] sxl:w-[43%] lg:w-[43%] md:w-full sm:w-full xd:w-full flex flex-col     ">
          <div className="xxl:hidden    bxl:hidden xl:hidden sxl:hidden lg:hidden md:flex sm:flex xd:flex flex-row gap-[1.5rem] justify-center items-center">
            <img src={Aptos} alt="" className="w-[5rem]" />
            <img src={X} alt=" " />
            <img src={Kana} alt="" className="w-[7rem]" />
          </div>
          <img src={GrantBg} alt="" className="  mix-blend-screen" />
          <Marquee className="  !z-[0]  w-full overflow-x-hidden    ">
            <span className="text-[0.875rem]  mr-[1rem] backdrop-blur-[1.25rem] p-[1.5rem] h-[9rem] !w-[18rem] flex flex-col gap-[1rem] justify-center items-center rounded-[1rem] bg-[rgba(23,24,26,0.50)] text-[rgba(255,255,255,0.80)] font-manrope font-[400] text-[0.875rem] text-center">
              <img src={ImprovedUx} alt="" />
              Improved UX
            </span>
            <span className="text-[0.875rem] mr-[1rem] backdrop-blur-[1.25rem] p-[1.5rem] h-[9rem] !w-[18rem] flex flex-col gap-[1rem] justify-center items-center rounded-[1rem] bg-[rgba(23,24,26,0.50)] text-[rgba(255,255,255,0.80)] font-manrope font-[400] text-[0.875rem] text-center">
              <img src={Lightning} alt="" />
              Super Fast Transactions
            </span>
            <span className="text-[0.875rem] mr-[1rem] backdrop-blur-[1.25rem] p-[1.5rem] h-[9rem] !w-[18rem] flex flex-col gap-[1rem] justify-center items-center rounded-[1rem] bg-[rgba(23,24,26,0.50)] text-[rgba(255,255,255,0.80)] font-manrope font-[400] text-[0.875rem] text-center">
              <img src={Coins} alt="" />
              <div className="flex flex-col justify-center items-center ">
                200 - 250k transactions
                <div className="text-[0.75rem] text-[#777879]">
                  sponsored everyday
                </div>
              </div>
            </span>
            <span className="text-[0.875rem] mr-[1rem] backdrop-blur-[1.25rem] p-[1.5rem] h-[9rem] !w-[18rem] flex flex-col gap-[1rem] justify-center items-center rounded-[1rem] bg-[rgba(23,24,26,0.50)] text-[rgba(255,255,255,0.80)] font-manrope font-[400] text-[0.875rem] text-center">
              <img src={Shield} alt="" />
              <div className="flex flex-col justify-center items-center ">
                Special admin and policy control
                <div className="text-[0.75rem] text-[#777879]">
                  via user & function whitelisting
                </div>
              </div>
            </span>
            <span className="text-[0.875rem] mr-[1rem] backdrop-blur-[1.25rem] p-[1.5rem] h-[9rem] !w-[18rem] flex flex-col gap-[1rem] justify-center items-center rounded-[1rem] bg-[rgba(23,24,26,0.50)] text-[rgba(255,255,255,0.80)] font-manrope font-[400] text-[0.875rem] text-center">
              <img src={Wallet} alt="" />
              AIP-319 based Fee Payer Wallet <br />
              to maintain efficient transaction
              <br /> processing & scaling opportunities
            </span>
          </Marquee>
        </div>
        <div className="  xxl:w-[57%] bxl:w-[57%] xl:w-[57%] sxl:w-[57%] lg:w-[57%] md:w-full sm:w-full xd:w-full flex flex-col  xxl:pl-[6rem] xxl:pr-[3rem]  bxl:pl-[6rem] bxl:pr-[3rem]  xl:pl-[6rem] xl:pr-[3rem] sxl:pl-[6rem] sxl:pr-[3rem]  lg:pl-[6rem] lg:pr-[3rem]  md:pl-[1rem] md:pr-[1rem] sm:px-[1rem] xd:px-[1rem] ">
          <div className="xxl:flex bxl:flex xl:flex sxl:flex lg:flex md:hidden sm:hidden xd:hidden flex-row gap-[1.5rem] justify-start items-center">
            <img src={Aptos} alt="" />
            <img src={X} alt=" " />
            <img src={Kana} alt="" />
          </div>
          <div className="text-[1.95rem] font-manrope font-[800] text-[#E0E0E0] pt-[2.5rem] md:text-center sm:text-center xd:text-center">
            Kana Labs Paymaster For Aptos
          </div>
          <div className="pt-[0.5rem] pb-[1.25rem] font-manrope text-[#777879] text-[0.75rem] font-[400] ">
            Kana Labs Paymaster has been live on the Aptos Network for some time
            now. As the first and largest Paymaster service in the Aptos
            ecosystem today, we have been live on the mainnet for four months.
          </div>
          <div className="text-[#777879] text-[0.75rem] font-[400] ">
            Kana Labs Paymaster created specifically for the Aptos network based
            on{" "}
            <span className=" font-[800] text-[#2ED3B7]  pl-[0.125rem]">
              AIP-39
            </span>
            , has been battle-tested and proven in the mainnet, Sponsoring more
            than 15 Million transactions for approximately 3 Million users
            across multiple dApps in SocialFi & DeFi markets.
          </div>

          <div className="py-[3rem] flex flex-col justify-start items-center gap-[1rem]">
            <div className="w-full rounded-[1rem] text-[rgba(255,255,255,0.80)] text-[0.875rem] font-manrope font-[800] flex flex-col gap-[1rem] justify-start items-start bg-[#17181A] p-[1rem_1.5rem]">
              What benefits are there for your dApp?
              <div className="flex flex-col gap-[0.5rem] w-full">
                <div className="flex xxl:flex-row bxl:flex-row xl:flex-row sxl:flex-row lg:flex-row md:flex-col sm:flex-col xd:flex-col  justify-center gap-[0.5rem] items-start w-full">
                  <div className="flex-[0.5] text-[rgba(255,255,255,0.80)] font-[400] text-[0.75rem] font-manrope bg-[#0C0C0D] rounded-[0.5rem] border-[1px] p-[0.5rem_1rem] border-[rgba(255,255,255,0.10)]">
                    Improved user onboarding & retention
                    <br />
                    <br />
                  </div>
                  <div className="flex-[0.5] text-[rgba(255,255,255,0.80)] font-[400] text-[0.75rem] font-manrope bg-[#0C0C0D] rounded-[0.5rem] border-[1px] p-[0.5rem_1rem] border-[rgba(255,255,255,0.10)]">
                    Either the platform or a third party can anonymously sponsor
                    gas fees
                  </div>
                </div>
                <div className="flex xxl:flex-row bxl:flex-row xl:flex-row sxl:flex-row lg:flex-row md:flex-col sm:flex-col xd:flex-col  justify-center gap-[0.5rem] items-start w-full">
                  <div className="flex-[0.5] text-[rgba(255,255,255,0.80)] font-[400] text-[0.75rem] font-manrope bg-[#0C0C0D] rounded-[0.5rem] border-[1px] p-[0.5rem_1rem] border-[rgba(255,255,255,0.10)]">
                    Simplified transaction experience for your customers
                  </div>
                  <div className="flex-[0.5] text-[rgba(255,255,255,0.80)] font-[400] text-[0.75rem] font-manrope bg-[#0C0C0D] rounded-[0.5rem] border-[1px] p-[0.5rem_1rem] border-[rgba(255,255,255,0.10)]">
                    Better control over Gas Fees Sponsor without too much
                    technical fuss
                  </div>
                </div>
              </div>
              <div className="flex flex-col gap-[1rem]  text-[rgba(255,255,255,0.80)] text-[0.75rem] font-[400]">
                <div>
                  To celebrate our successful mainnet run, we’ve partnered with
                  the Aptos Foundation to launch a gas credits program! This
                  program will run for the next 6 months during which teams will
                  be selected to receive up to 1,000 APT in Kana Labs Paymaster
                  credits.
                </div>
                <div>
                  Eligibility criteria:
                  <li>
                    The dApp must be live on Aptos mainnet with a minimum of
                    1,000 weekly transactions.
                  </li>
                  <li>
                    {" "}
                    The dApp agrees to a campaign on Twitter highlighting their
                    project and usage of gas credits.
                  </li>
                  <li>
                    {" "}
                    DeFi dApps must have a submitted audit report and multi-sig
                    for treasury management.{" "}
                  </li>
                </div>
              </div>
              <div className="pt-[0.625rem]">
                <a
                  href="https://forms.gle/UwKg34PivRAGFAwg8"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <button className="buy-button w-auto">
                    <div className="buy-button-inner !h-[56px] text-[#2ED3B7] text-[1rem] font-manrope font-[800]">
                      Apply now
                    </div>
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Grant;


