import { AccountAddress } from "@aptos-labs/ts-sdk";
import { getAptosRpcEndPoint } from "./sign";

export let balanceValueWithoutDecimal: any;

export const fetchBalanceFromUser = async (address: any, network: any) => {
  try {
    const aptos = getAptosRpcEndPoint(network);
    const accountAddress = AccountAddress.from(address);
    const aptosBalance = await aptos.getAccountAPTAmount({
      accountAddress: accountAddress,
    });
    balanceValueWithoutDecimal = (aptosBalance / 1e8).toFixed(8);
    return balanceValueWithoutDecimal;
  } catch (error: any) {
    throw error;
  }
};


