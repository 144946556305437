import React, { useCallback, useEffect, useState } from "react";
import Kana from "../assets/icons/kana_logo.svg";
import LogoKana from "../assets/icons/Logo-kana.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { useStore } from "store";
import removeWhitelist from "../assets/icons/removewhitelist.svg";
import Bank from "../assets/icons/bank.svg";
import Clock from "../assets/icons/clock-rewind.svg";
import deposit from "../assets/icons/Deposit.svg";
import Functions from "../assets/icons/function.svg";
import Deposit from "./Deposit";
import { useWallet } from "@aptos-labs/wallet-adapter-react";
import Admin from "../assets/icons/users-admin.svg";
const Sidebar = () => {
  const {
    activeTab,
    updateActiveTab,
    dappDetails,
    checkIsAdmin,
    setChangeHeader,
  } = useStore();
  const navigate = useNavigate();
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const { network } = useWallet();
  const location = useLocation();

  const handleSideNavigation = useCallback(() => {
    const currentLocation = location.pathname;
    if (currentLocation === "/dashboard") {
      updateActiveTab("/dashboard");
    } else if (currentLocation === "/deposit") {
      updateActiveTab("deposit");
    } else if (currentLocation === "/users-whitelist") {
      updateActiveTab("/users-whitelist");
    } else if (currentLocation === "/transactions-history") {
      updateActiveTab("/transactions-history");
    } else if (currentLocation === "/register") {
      updateActiveTab("/register");
    } else if (currentLocation === "/skeleton") {
      updateActiveTab("/skeleton");
    } else if (currentLocation === "/functions-whitelist") {
      updateActiveTab("/functions-whitelist");
    } else if (currentLocation === "/admin") {
      updateActiveTab("/admin");
    }
  }, [location.pathname, updateActiveTab]);

  useEffect(() => {
    handleSideNavigation();
  }, [handleSideNavigation, location]);

  const handleClick = (id: any) => {
    if (id === "dashboard") {
      updateActiveTab(id);
      navigate("/dashboard");
      setChangeHeader(false);
    }
    if (id === "users-whitelist") {
      updateActiveTab(id);
      navigate("/users-whitelist");
      setChangeHeader(false);
    }
    if (id === "transactions-history") {
      updateActiveTab(id);
      navigate("/transactions-history");
      setChangeHeader(false);
    }
    if (id === "register") {
      updateActiveTab(id);
      navigate("/register");
      setChangeHeader(false);
    }
    if (id === "skeleton") {
      updateActiveTab(id);
      navigate("/skeleton");
    }
    if (id === "functions-whitelist") {
      updateActiveTab(id);
      navigate("/functions-whitelist");
      setChangeHeader(false);
    }
    if (id === "admin") {
      updateActiveTab(id);
      navigate("/admin");
      setChangeHeader(true);
    }
  };

  const handleMouseEnter = () => {
    setIsOpen(true);
  };
  const handleMouseLeave = () => {
    setIsOpen(false);
  };

  const handleCloseDepositModel = () => {
    setPopupOpen(false);
  };

  return (
    <>
      <div
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        className={` !z-[2] !fixed inset-0  h-full bg-[#17181A]  flex flex-col gap-4 items-center justify-between font-manrop py-5  xxl:flex xl:flex lg:flex md:hidden sm:hidden xd:hidden w-auto ease-in-out duration-00 ${
          isOpen ? "!w-[20rem] ease-in duration-50 " : "!w-[6rem] !z-[1]  "
        }`}
      >
        <div className="w-full hover:w-full bg-transparent flex flex-col text-left items-center h-full justify-between p-[1rem] w-full  ">
          <div className="flex flex-col text-left items-start h-full justify-start w-full">
            <div className=" px-[1rem] top-0 left-0  !sticky  mb-[2.25rem] bg-[#17181A]  ">
              {isOpen ? (
                <img
                  className="bg-transparent !h-[2rem] !w-full  "
                  src={Kana}
                  alt="KanaLogo"
                />
              ) : (
                <img
                  className="bg-transparent !h-[2rem] !w-full"
                  src={LogoKana}
                  alt="Kana"
                />
              )}
            </div>
            <div
              className={`  flex flex-col justify-start items-start bg-transparent w-full gap-[8px]`}
            >
              {checkIsAdmin && dappDetails.length > 0 ? (
                <>
                  <div className="w-full cursor-pointer   h-[52px] flex flex-row justify-start items-center    bg-transparent gap-[16px]">
                    <div
                      className={`   text-[16px] my-[4px]   h-auto  text-[16px] font-[400] flex flex-row justify-start items-center ${
                        isOpen
                          ? "py-[1rem] px-[1.4rem] w-full"
                          : "py-[1.2rem] px-[1rem]  w-full "
                      }  rounded-[16px]  hover:opacity-[1] hover:text-[white] hover:bg-[rgba(255,255,255,0.06)] hover:font-[700] gap-[16px]  ${
                        activeTab === "/dashboard"
                          ? "font-[700] opacity-[1] !bg-[rgba(255,255,255,0.06)] "
                          : "opacity-[0.5]"
                      }`}
                      onClick={() => handleClick("dashboard")}
                    >
                      {isOpen ? (
                        <div className=" text-[white] flex flex-row justify-start items-center gap-[1rem] cursor-pointer">
                          <img
                            className="!h-[1rem]"
                            src={removeWhitelist}
                            alt="Bank"
                          />
                          <div className="">Dashboard</div>
                        </div>
                      ) : (
                        <div className="  flex flex-row justify-center items-center w-full gap-[1rem] rounded-[1rem]">
                          <img
                            className="!h-[1rem]"
                            src={removeWhitelist}
                            alt="Bank"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  {!dappDetails && (
                    <div className="w-full cursor-pointer   h-[52px] flex flex-row justify-start items-center    bg-transparent gap-[16px]">
                      <div
                        className={`   text-[16px] my-[4px]   h-auto  text-[16px] font-[400] flex flex-row justify-start items-center ${
                          isOpen
                            ? "py-[1rem] px-[1.4rem] w-full"
                            : "py-[1.2rem] px-[1rem]  w-full"
                        }  rounded-[16px]  hover:opacity-[1] hover:text-[white] hover:bg-[rgba(255,255,255,0.06)] hover:font-[700] gap-[16px]  ${
                          activeTab === "/register"
                            ? "font-[700] opacity-[1] !bg-[rgba(255,255,255,0.06)] "
                            : "opacity-[0.5]"
                        }`}
                        onClick={() => handleClick("register")}
                      >
                        {isOpen ? (
                          <div className=" text-[white] flex flex-row justify-start items-center gap-[1rem] cursor-pointer">
                            <img className="!h-[1rem]" src={Bank} alt="Bank" />
                            <div className="">Register</div>
                          </div>
                        ) : (
                          <div className="  flex flex-row justify-center items-center w-full gap-[1rem] rounded-[1rem]">
                            <img className="!h-[1rem]" src={Bank} alt="Bank" />
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                  <div className="w-full cursor-pointer   h-[52px] flex flex-row justify-start items-center    bg-transparent gap-[16px]">
                    <div
                      className={`   text-[16px] my-[4px]   h-auto  text-[16px] font-[400] flex flex-row justify-start items-center ${
                        isOpen
                          ? "py-[1rem] px-[1.4rem] w-full"
                          : "py-[1.2rem] px-[1rem]   w-full"
                      }  rounded-[16px]  hover:opacity-[1] hover:text-[white] hover:bg-[rgba(255,255,255,0.06)] hover:font-[700] gap-[16px]  ${
                        activeTab === "/users-whitelist"
                          ? "font-[700] opacity-[1] !bg-[rgba(255,255,255,0.06)] "
                          : "opacity-[0.5]"
                      }`}
                      onClick={() => handleClick("users-whitelist")}
                    >
                      {isOpen ? (
                        <div className=" text-[white] flex flex-row justify-start items-center gap-[1rem] cursor-pointer">
                          <img className="!h-[1rem]" src={deposit} alt="Bank" />
                          <div>Users Whitelist</div>
                        </div>
                      ) : (
                        <div className=" flex flex-row justify-center items-center w-full cursor-pointer">
                          <img className="!h-[1rem]" src={deposit} alt="Bank" />
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="w-full cursor-pointer   h-[52px] flex flex-row justify-start items-center    bg-transparent gap-[16px]">
                    <div
                      className={`   text-[16px] my-[4px]   h-auto  text-[16px] font-[400] flex flex-row justify-start items-center ${
                        isOpen
                          ? "py-[1rem] px-[1.4rem] w-full"
                          : "py-[1.2rem] px-[1rem] w-full"
                      }  rounded-[16px]  hover:opacity-[1] hover:text-[white] hover:bg-[rgba(255,255,255,0.06)] hover:font-[700] gap-[16px]  ${
                        activeTab === "/transactions-history"
                          ? "font-[700] opacity-[1] !bg-[rgba(255,255,255,0.06)] "
                          : "opacity-[0.5]"
                      }`}
                      onClick={() => handleClick("transactions-history")}
                    >
                      {isOpen ? (
                        <div className=" text-[white] flex flex-row justify-start items-center gap-[1rem] cursor-pointer">
                          <img className="!h-[1rem]" src={Clock} alt="Bank" />

                          <div>Transaction History</div>
                        </div>
                      ) : (
                        <div className=" flex flex-row justify-center items-center w-full cursor-pointer">
                          <img className="!h-[1rem]" src={Clock} alt="Bank" />
                        </div>
                      )}
                    </div>
                  </div>

                  {network?.name?.toLowerCase() === "mainnet" && (
                    <div className="w-full cursor-pointer h-[52px] flex flex-row justify-start items-center bg-transparent gap-[16px]">
                      <div
                        className={`text-[16px] my-[4px] h-auto text-[16px] font-[400] flex flex-row justify-start items-center ${
                          isOpen
                            ? "py-[0.2rem] px-[1.4rem] w-full"
                            : "py-[1.2rem] px-[1rem] w-full "
                        } rounded-[16px] hover:opacity-[1] hover:text-[white] hover:bg-[rgba(255,255,255,0.06)] hover:font-[700] gap-[16px] ${
                          activeTab === "/functions-whitelist"
                            ? "font-[700] opacity-[1] !bg-[rgba(255,255,255,0.06)] "
                            : "opacity-[0.5]"
                        }`}
                        onClick={() => handleClick("functions-whitelist")}
                      >
                        {isOpen ? (
                          <div className="text-[white] flex flex-row justify-start items-center gap-[1.5rem] cursor-pointer">
                            <img
                              className="!h-[1rem] "
                              src={Functions}
                              alt="Bank"
                            />
                            <div>Functions & Module Whitelist</div>
                          </div>
                        ) : (
                          <div className="flex flex-row justify-center items-center w-full cursor-pointer">
                            <img
                              className="!h-[1rem] "
                              src={Functions}
                              alt="Bank"
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                  <div className="w-full cursor-pointer   h-[52px] flex flex-row justify-start items-center    bg-transparent gap-[1rem]">
                    <div
                      className={`   text-[1rem] my-[4px]   h-auto font-[400] flex flex-row justify-start items-center ${
                        isOpen
                          ? "py-[1rem] px-[1.4rem] w-full"
                          : "py-[1.2rem] px-[1rem] w-full"
                      }  rounded-[16px]  hover:opacity-[1] hover:text-[white] hover:bg-[rgba(255,255,255,0.06)] hover:font-[700] gap-[1rem]  ${
                        activeTab === "/admin"
                          ? "font-[700] opacity-[1] !bg-[rgba(255,255,255,0.06)] "
                          : "opacity-[0.5]"
                      }`}
                      onClick={() => handleClick("admin")}
                    >
                      {isOpen ? (
                        <div className=" text-[white] flex flex-row justify-start items-center gap-[1rem] cursor-pointer">
                          <img className="!h-[1rem]" src={Admin} alt="Bank" />

                          <div>Admin</div>
                        </div>
                      ) : (
                        <div className=" flex flex-row justify-center items-center w-full cursor-pointer">
                          <img className="!h-[1rem]" src={Admin} alt="Bank" />
                        </div>
                      )}
                    </div>
                  </div>
                </>
              ) : checkIsAdmin ? (
                <>
                  <div className="w-full cursor-pointer   h-[52px] flex flex-row justify-start items-center    bg-transparent gap-[1rem]">
                    <div
                      className={`   text-[1rem] my-[4px]   h-auto font-[400] flex flex-row justify-start items-center ${
                        isOpen
                          ? "py-[1rem] px-[1.4rem] w-full"
                          : "py-[1.2rem] px-[1rem] w-full"
                      }  rounded-[16px]  hover:opacity-[1] hover:text-[white] hover:bg-[rgba(255,255,255,0.06)] hover:font-[700] gap-[1rem]  ${
                        activeTab === "/admin"
                          ? "font-[700] opacity-[1] !bg-[rgba(255,255,255,0.06)] "
                          : "opacity-[0.5]"
                      }`}
                      onClick={() => handleClick("admin")}
                    >
                      {isOpen ? (
                        <div className=" text-[white] flex flex-row justify-start items-center gap-[1rem] cursor-pointer">
                          <img className="!h-[1rem]" src={Admin} alt="Bank" />

                          <div>Admin</div>
                        </div>
                      ) : (
                        <div className=" flex flex-row justify-center items-center w-full cursor-pointer">
                          <img className="!h-[1rem]" src={Admin} alt="Bank" />
                        </div>
                      )}
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="w-full cursor-pointer   h-[52px] flex flex-row justify-start items-center    bg-transparent gap-[16px]">
                    <div
                      className={`   text-[16px] my-[4px]   h-auto  text-[16px] font-[400] flex flex-row justify-start items-center ${
                        isOpen
                          ? "py-[1rem] px-[1.4rem] w-full"
                          : "py-[1.2rem] px-[1rem]  w-full "
                      }  rounded-[16px]  hover:opacity-[1] hover:text-[white] hover:bg-[rgba(255,255,255,0.06)] hover:font-[700] gap-[16px]  ${
                        activeTab === "/dashboard"
                          ? "font-[700] opacity-[1] !bg-[rgba(255,255,255,0.06)] "
                          : "opacity-[0.5]"
                      }`}
                      onClick={() => handleClick("dashboard")}
                    >
                      {isOpen ? (
                        <div className=" text-[white] flex flex-row justify-start items-center gap-[1rem] cursor-pointer">
                          <img
                            className="!h-[1rem]"
                            src={removeWhitelist}
                            alt="Bank"
                          />
                          <div className="">Dashboard</div>
                        </div>
                      ) : (
                        <div className="  flex flex-row justify-center items-center w-full gap-[1rem] rounded-[1rem]">
                          <img
                            className="!h-[1rem]"
                            src={removeWhitelist}
                            alt="Bank"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  {!dappDetails && (
                    <div className="w-full cursor-pointer   h-[52px] flex flex-row justify-start items-center    bg-transparent gap-[16px]">
                      <div
                        className={`   text-[16px] my-[4px]   h-auto  text-[16px] font-[400] flex flex-row justify-start items-center ${
                          isOpen
                            ? "py-[1rem] px-[1.4rem] w-full"
                            : "py-[1.2rem] px-[1rem]  w-full"
                        }  rounded-[16px]  hover:opacity-[1] hover:text-[white] hover:bg-[rgba(255,255,255,0.06)] hover:font-[700] gap-[16px]  ${
                          activeTab === "/register"
                            ? "font-[700] opacity-[1] !bg-[rgba(255,255,255,0.06)] "
                            : "opacity-[0.5]"
                        }`}
                        onClick={() => handleClick("register")}
                      >
                        {isOpen ? (
                          <div className=" text-[white] flex flex-row justify-start items-center gap-[1rem] cursor-pointer">
                            <img className="!h-[1rem]" src={Bank} alt="Bank" />
                            <div className="">Register</div>
                          </div>
                        ) : (
                          <div className="  flex flex-row justify-center items-center w-full gap-[1rem] rounded-[1rem]">
                            <img className="!h-[1rem]" src={Bank} alt="Bank" />
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                  <div className="w-full cursor-pointer   h-[52px] flex flex-row justify-start items-center    bg-transparent gap-[16px]">
                    <div
                      className={`   text-[16px] my-[4px]   h-auto  text-[16px] font-[400] flex flex-row justify-start items-center ${
                        isOpen
                          ? "py-[1rem] px-[1.4rem] w-full"
                          : "py-[1.2rem] px-[1rem]   w-full"
                      }  rounded-[16px]  hover:opacity-[1] hover:text-[white] hover:bg-[rgba(255,255,255,0.06)] hover:font-[700] gap-[16px]  ${
                        activeTab === "/users-whitelist"
                          ? "font-[700] opacity-[1] !bg-[rgba(255,255,255,0.06)] "
                          : "opacity-[0.5]"
                      }`}
                      onClick={() => handleClick("users-whitelist")}
                    >
                      {isOpen ? (
                        <div className=" text-[white] flex flex-row justify-start items-center gap-[1rem] cursor-pointer">
                          <img className="!h-[1rem]" src={deposit} alt="Bank" />
                          <div>Users Whitelist</div>
                        </div>
                      ) : (
                        <div className=" flex flex-row justify-center items-center w-full cursor-pointer">
                          <img className="!h-[1rem]" src={deposit} alt="Bank" />
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="w-full cursor-pointer   h-[52px] flex flex-row justify-start items-center    bg-transparent gap-[16px]">
                    <div
                      className={`   text-[16px] my-[4px]   h-auto  text-[16px] font-[400] flex flex-row justify-start items-center ${
                        isOpen
                          ? "py-[1rem] px-[1.4rem] w-full"
                          : "py-[1.2rem] px-[1rem] w-full"
                      }  rounded-[16px]  hover:opacity-[1] hover:text-[white] hover:bg-[rgba(255,255,255,0.06)] hover:font-[700] gap-[16px]  ${
                        activeTab === "/transactions-history"
                          ? "font-[700] opacity-[1] !bg-[rgba(255,255,255,0.06)] "
                          : "opacity-[0.5]"
                      }`}
                      onClick={() => handleClick("transactions-history")}
                    >
                      {isOpen ? (
                        <div className=" text-[white] flex flex-row justify-start items-center gap-[1rem] cursor-pointer">
                          <img className="!h-[1rem]" src={Clock} alt="Bank" />

                          <div>Transaction History</div>
                        </div>
                      ) : (
                        <div className=" flex flex-row justify-center items-center w-full cursor-pointer">
                          <img className="!h-[1rem]" src={Clock} alt="Bank" />
                        </div>
                      )}
                    </div>
                  </div>

                  {network?.name?.toLowerCase() === "mainnet" && (
                    <div className="w-full cursor-pointer h-[52px] flex flex-row justify-start items-center bg-transparent gap-[16px]">
                      <div
                        className={`text-[16px] my-[4px] h-auto text-[16px] font-[400] flex flex-row justify-start items-center ${
                          isOpen
                            ? "py-[1rem] px-[1.4rem] w-full"
                            : "py-[1.2rem] px-[1rem] w-full "
                        } rounded-[16px] hover:opacity-[1] hover:text-[white] hover:bg-[rgba(255,255,255,0.06)] hover:font-[700] gap-[16px] ${
                          activeTab === "/functions-whitelist"
                            ? "font-[700] opacity-[1] !bg-[rgba(255,255,255,0.06)] "
                            : "opacity-[0.5]"
                        }`}
                        onClick={() => handleClick("functions-whitelist")}
                      >
                        {isOpen ? (
                          <div className="text-[white] flex flex-row justify-start items-center gap-[1rem] cursor-pointer">
                            <img
                              className="!h-[1rem] "
                              src={Functions}
                              alt="Bank"
                            />
                            <div>Functions & Module Whitelist</div>
                          </div>
                        ) : (
                          <div className="flex flex-row justify-center items-center w-full cursor-pointer">
                            <img
                              className="!h-[1rem] "
                              src={Functions}
                              alt="Bank"
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      {isPopupOpen && (
        <Deposit handleCloseDepositModel={handleCloseDepositModel} />
      )}
    </>
  );
};

export default Sidebar;
