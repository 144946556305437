import dotenv from "dotenv";
import { Aptos } from "@aptos-labs/ts-sdk";
import { aptosMainnet, aptosTestnet } from "./constants";
dotenv.config();

export const getAptosRpcEndPoint = (network: any): Aptos => {
  if (network === "mainnet") {
    return aptosMainnet;
  } else if (network === "testnet") {
    return aptosTestnet;
  } else {
    throw new Error("Invalid network");
  }
};
