import React, { useCallback, useEffect, useState } from "react";
import { useWallet } from "@aptos-labs/wallet-adapter-react";
import { useStore } from "store";
import { toast } from "react-toastify";
import { getAptosRpcEndPoint } from "utils/sign";
import { useNavigate } from "react-router-dom";
import { balanceValueWithoutDecimal } from "utils/userBalanceFetch";
import Xclose from "../assets/icons/x-close.svg";
import { fetchBalanceFromFeePayer } from "utils/feePayerBalanceFetch";
import {
  checkAptBalance,
  depositDetails,
  getWithdrawalAmount,
  handleDappDetails,
  withdrawDetails,
} from "utils/helpers";
import Copy from "../assets/icons/Icon-copy.svg";
import { copyAddress } from "utils/helpers";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Sidebar from "./Sidebar";
import Header from "./Header";
import Footer from "./Footer";

interface DepositInterface {
  handleCloseDepositModel(): void;
}

const Deposit = (props: DepositInterface) => {
  const { handleCloseDepositModel } = props;
  const navigate = useNavigate();
  const { connected, signAndSubmitTransaction, disconnect, network, account } =
    useWallet();
  const {
    updateWalletConnect,
    updateDappDetails,
    updateApiKey,
    signature,
    updateWhitelisted,
    updateFeePayerAddresses,
    publicKey,
    updateFeePayerBalance,
    feePayerAddresses,
    updateSignature,
    balance,
    walletNetwork,
    apikey,
    address,
    setGetFeePayerBalance,
    getFeePayerBalance,
  } = useStore();

  const [amount, setAmount] = useState<string>("");
  const [selectedFeePayer, setSelectedFeePayer] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [details, setDetails] = useState<any[]>([]);
  const [totalFeePayerBalances, setTotalFeePayerBalances] = useState<any>();
  const [selectedTab, setSelectedTab] = useState("deposit");
  const [howMuchCanWithdraw, setHowMuchCanWithdraw] = useState<any>()

  let loading: any;
  const getDappDetails = useCallback(async () => {
    if (connected) {
      updateWalletConnect(false);
      setIsLoading(true);
      try {
        const response: any = await handleDappDetails(
          address,
          signature,
          publicKey
        );
        if (response.status === 200 && response.data.success) {
          const dappData = JSON.parse(response.data.dapp);
          const wrappedDappArray = [dappData];
          const whitelistData = wrappedDappArray[0].whitelist;

          if (response.data.dapp.length > 0) {
            setDetails(wrappedDappArray);
            updateDappDetails(response.data.dapp);
            updateApiKey(wrappedDappArray[0].api_key);
            updateWhitelisted(whitelistData);
            updateFeePayerAddresses(
              wrappedDappArray[0].feepayer_accounts.map(
                (account: any) => account.address
              )
            );
            setIsLoading(false);
          } else {
            navigate("/register");
          }
        }
      } catch (error: any) {
        if (
          error?.response?.data?.success === false &&
          error?.response?.data?.message === "Paymaster not available"
        ) {
          navigate("/register");
        } else {
          console.error(error);
        }
      }
    } else {
      toast.warning("Please connect to wallet");
    }
  }, [
    connected,
    updateDappDetails,
    address,
    publicKey,
    navigate,
    updateApiKey,
    updateFeePayerAddresses,
    updateWhitelisted,
    signature,
    updateWalletConnect,
  ]);

  useEffect(() => {
    if (connected) {
      setIsLoading(true);
      getDappDetails();
    } else {
      setDetails([]);
      updateApiKey("");
      updateSignature("");
    }
  }, [
    connected,
    updateDappDetails,
    disconnect,
    getDappDetails,
    updateApiKey,
    updateSignature,
    walletNetwork,
    address,
    publicKey,
    signature,
  ]);

  const canAbleToWithdraw = async () => {
    try {
      const data = await getWithdrawalAmount(
        feePayerAddresses[0],
        account?.address as string
      );

      if (data.success) {
        setHowMuchCanWithdraw(data.balance);
      } else {
        console.log("data.error: ", data.error);
      }
    } catch (error) {
      // Handle any errors that might occur during the asynchronous operation
      console.error("Error in canAbleToWithdraw:", error);
      // Optionally, rethrow the error to propagate it up the call stack
      throw error;
    }
  };

  // const toggleDropdown = () => {
  //   setIsOpenDropDown(!isOpenDropDown);
  // };

  // const deposit = async () => {
  //   const depositAmountValue = parseFloat(amount);
  //   if (!connected) {
  //     toast.warning("Please connect to the wallet");
  //     return;
  //   }
  //   if (!apikey) {
  //     toast.info("Please register");
  //     setIsLoading(false);
  //     return;
  //   }
  //   if (isNaN(depositAmountValue) || getDecimalPlaces(amount) > 8) {
  //     toast.warning("Invalid deposit amount");
  //     return;
  //   }
  //   const convertedAmount = depositAmountValue * 100000000;
  //   if (convertedAmount <= 0) {
  //     toast.warning("Invalid deposit amount");
  //     return;
  //   }
  //   if (network?.name?.toLowerCase() !== "mainnet") {
  //     toast.warning("Switch to the Mainnet");
  //     navigate("/");
  //     disconnect();
  //     return;
  //   }
  //   if (depositAmountValue >= balanceValueWithoutDecimal) {
  //     toast.warning("Insufficient balance");
  //     setIsLoading(false);
  //     return;
  //   }
  //   try {
  //     loading = toast.loading("Loading...");
  //     const hash = await signAndSubmitTransaction({
  //       sender: account?.address,
  //       data: {
  //         function:
  //           "0x8706612f5a40a594eaf837280282faaee4e101e70f05ac92cb2c43f4d83d072e::KanaGasStation::deposit",
  //         typeArguments: ["0x1::aptos_coin::AptosCoin"],
  //         functionArguments: [convertedAmount.toString()],
  //       },
  //     });
  //     try {
  //       const responseHash = await getAptosRpcEndPoint(
  //         network?.name?.toLowerCase()
  //       ).waitForTransaction({ transactionHash: hash?.hash });

  //       toast.dismiss(loading);
  //       const Hash = responseHash?.hash.toString();
  //       if (Hash.length > 0) {
  //         setIsLoading(true);
  //         const explorerLink = `https://explorer.aptoslabs.com/txn/${Hash}?network=mainnet`;
  //         toast.success(
  //           <div>
  //             Deposited successfully
  //             <button
  //               onClick={() => window.open(explorerLink, "_blank")}
  //               className="explorer-link-button"
  //             >
  //               Click to see in explorer
  //             </button>
  //           </div>
  //         );
  //         await getDappDetails();
  //         setIsLoading(false);
  //         navigate("/");
  //       }
  //     } catch (error) {
  //       console.error(error);
  //     }
  //   } catch (error) {
  //     toast.error("User rejected the deposit");
  //     toast.dismiss(loading);
  //     setIsLoading(false);
  //   }
  // };

  // const depositEqualize = async () => { // used for later version
  //   const depositAmountValue = parseFloat(amount);
  //   if (connected) {
  //     if (apikey) {
  //       if (!isNaN(depositAmountValue)) {
  //         if (getDecimalPlaces(amount) <= 8) {
  //           const convertedAmount = depositAmountValue * 100000000;
  //           if (convertedAmount > 0) {
  //             if (walletNetwork === true) {
  //               if (convertedAmount < balanceValueWithoutDecimal) {
  //                 try {
  //                   loading = toast.loading("Loading...");
  //                   const payload = {
  //                     function:
  //                       "0x14f311c8f4bd73abd6751875a83a7ce394f1e1f22267695511e223b6d1f3727e::KanaGasRegistry::deposit_and_equalize",
  //                     type_arguments: ["0x1::aptos_coin::AptosCoin"],
  //                     arguments: [convertedAmount.toString()],
  //                   };

  //                   const hash = await submitAptosEntryFunctionPayload(
  //                     payload,
  //                     signAndSubmitTransaction,
  //                     "40000"
  //                   );

  //                   toast.dismiss(loading);
  //                   const Hash = hash.toString();
  //                   if (Hash.length > 0) {
  //                     setIsLoading(true);
  //                     const explorerLink = `https://explorer.aptoslabs.com/txn/${Hash}?network=mainnet`;
  //                     toast.success(
  //                       <div>
  //                         Deposited successfully
  //                         <button
  //                           onClick={() => window.open(explorerLink, "_blank")}
  //                           className="explorer-link-button"
  //                         >
  //                           Click to see in explorer
  //                         </button>
  //                       </div>
  //                     );
  //                     setIsLoading(false);
  //                     navigate("/");
  //                   }
  //                 } catch (error) {
  //                   toast.error("User rejected the deposit");
  //                   toast.dismiss(loading);
  //                   setIsLoading(false);
  //                 }
  //               } else {
  //                 toast.warning("Insufficient balance");
  //                 setIsLoading(false);
  //               }
  //             } else {
  //               toast.warning("Switch to the Mainnet");
  //               navigate("/");
  //               disconnect();
  //             }
  //           } else {
  //             toast.warning("Invalid deposit amount");
  //           }
  //         } else {
  //           toast.warning("Invalid deposit amount");
  //         }
  //       } else {
  //         toast.warning("Invalid deposit amount");
  //       }
  //     } else {
  //       toast.info("Please register");
  //       setIsLoading(false);
  //     }
  //   } else {
  //     toast.warning("Please connect to the wallet");
  //   }
  // };

  const depositSpecific = async (feePayeraddress: string) => {
    const depositAmountValue = parseFloat(amount);
    if (!connected) {
      toast.warning("Please connect to the wallet");
      return;
    }
    if (!apikey) {
      toast.info("Please register");
      setIsLoading(false);
      return;
    }
    if (isNaN(depositAmountValue) || getDecimalPlaces(amount) > 8) {
      toast.warning("Invalid deposit amount");
      return;
    }
    const convertedAmount = depositAmountValue * 100000000;
    if (convertedAmount <= 0) {
      toast.warning("Invalid deposit amount");
      return;
    }
    if (
      !(
        network?.name?.toLowerCase() === "mainnet" ||
        network?.name?.toLowerCase() === "testnet"
      )
    ) {
      toast.warning("Switch to the Mainnet");
      navigate("/dashboard");
      disconnect();
      return;
    }
    if (depositAmountValue >= balanceValueWithoutDecimal) {
      toast.warning("Insufficient balance");
      setIsLoading(false);
      return;
    }
    try {
      loading = toast.loading("Loading...");
      // const payload: any = {
      //   function:
      //     "0x8706612f5a40a594eaf837280282faaee4e101e70f05ac92cb2c43f4d83d072e::KanaGasStation::deposit_to_specific",
      //   typeArguments: ["0x1::aptos_coin::AptosCoin"],
      //   functionArguments: [feePayeraddress, convertedAmount.toString()],
      // };
      const payload: any = {
        function:
          network?.name?.toLowerCase() === "testnet"
            ? "0x1::aptos_account::transfer_coins"
            : "0x8706612f5a40a594eaf837280282faaee4e101e70f05ac92cb2c43f4d83d072e::KanaGasStation::deposit_to_specific",
        typeArguments: ["0x1::aptos_coin::AptosCoin"],
        functionArguments: [feePayeraddress, convertedAmount.toString()],
        ...(network?.name?.toLowerCase() === "testnet" && {
          type: "entry_function_payload",
        }),
      };

      const hash = await signAndSubmitTransaction({
        sender: account?.address,
        data: payload,
      });

      try {
        const responseHash = await getAptosRpcEndPoint(
          network?.name?.toLowerCase()
        ).waitForTransaction({ transactionHash: hash?.hash });
        toast.dismiss(loading);
        const Hash = responseHash?.hash.toString();
        if (Hash && Hash.length > 0) {
          setIsLoading(true);
          await depositDetails(
            feePayeraddress,
            address,
            convertedAmount.toString(),
            Hash,
            network?.name?.toLowerCase()
          );
          await canAbleToWithdraw();
          const explorerNetwork =
            network?.name?.toLowerCase() === "mainnet" ? "mainnet" : "testnet";
          const explorerLink = `https://explorer.aptoslabs.com/txn/${Hash}?network=${explorerNetwork}`;
          toast.success(
            <div>
              Deposited successfully
              <button
                onClick={() => window.open(explorerLink, "_blank")}
                className="explorer-link-button"
              >
                Click to see in explorer
              </button>
            </div>
          );
          await getDappDetails();
          setIsLoading(false);
          setSelectedFeePayer(feePayeraddress);
          navigate("/dashboard");
        } else {
          // Handle the scenario where Hash is undefined or empty
          toast.error("Transaction hash is invalid or empty");
          toast.dismiss(loading);
        }
      } catch (error: any) {
        toast.error(error.message);
      }
    } catch (error) {
      toast.error("User rejected the deposit");
      toast.dismiss(loading);
      setIsLoading(false);
    }
  };

  const fetchFeePayerBalance = useCallback(async () => {
    if (feePayerAddresses.length > 0) {
      const individualBalances = [] as any[];

      for (const address of feePayerAddresses) {
        try {
          const feepayerBalance: any = await fetchBalanceFromFeePayer(
            address,
            network?.name?.toLowerCase()
          );

          individualBalances.push({ address, balance: feepayerBalance });
          updateFeePayerBalance(individualBalances);
        } catch (error) {
          toast.error(`Error while fetching balance for address ${address}`);
        }
      }

      const balancesOnly = individualBalances.map((item: any) =>
        parseFloat(item.balance)
      );
      const totalBalance = balancesOnly.reduce(
        (acc, balance) => acc + balance,
        0
      );
      setTotalFeePayerBalances(totalBalance.toFixed(8));
    }
  }, [feePayerAddresses, network?.name, updateFeePayerBalance]);

  useEffect(() => {
    if (connected) {
      fetchFeePayerBalance();
    }
  }, [connected, fetchFeePayerBalance, network?.name, address, balance]);

  function getDecimalPlaces(value: any) {
    const match = (value + "").match(/(?:\.(\d+))?(?:[eE]([+-]?\d+))?$/);
    if (!match) return 0;
    return Math.max(
      0,
      (match[1] ? match[1].length : 0) - (match[2] ? +match[2] : 0)
    );
  }

  const handleDepositClick = async () => {
    await depositSpecific(feePayerAddresses[0]);
  };

  const handleWithdrawClick = async () => {
    await Withdraw(feePayerAddresses[0]);
  };

  const Withdraw = async (feePayeraddress: string) => {
    const withdrawAmountValue = parseFloat(amount);
    if (!connected) {
      toast.warning("Please connect to the wallet");
      return;
    }
    if (!apikey) {
      toast.info("Please register");
      setIsLoading(false);
      return;
    }
    if (isNaN(withdrawAmountValue) || getDecimalPlaces(amount) > 8) {
      toast.warning("Invalid withdraw amount");
      return;
    }
    const convertedAmount = withdrawAmountValue * 100000000;
    if (convertedAmount <= 0) {
      toast.warning("Invalid withdraw amount");
      return;
    }
    if (!(network?.name?.toLowerCase() === "mainnet")) {
      toast.warning("Switch to the Mainnet");
      navigate("/dashboard");
      disconnect();
      return;
    }

    if (convertedAmount > howMuchCanWithdraw) {
      toast.warning("Insufficient balance need to deposit first");
      setIsLoading(false);
      return;
    }

    try {
      loading = toast.loading("Loading...");
      const data = await withdrawDetails(
        network?.name?.toLowerCase(),
        signature,
        account?.address as string,
        convertedAmount,
        account?.publicKey as string,
        feePayeraddress
      );
      if (data.success) {
        const responseHash = await getAptosRpcEndPoint(
          network?.name?.toLowerCase()
        ).waitForTransaction({ transactionHash: data?.hash });
        toast.dismiss(loading);
        const Hash = responseHash?.hash?.toString();
        if (Hash && Hash.length > 0) {
          setIsLoading(true);
          await canAbleToWithdraw();
          const explorerNetwork =
            network?.name?.toLowerCase() === "mainnet" ? "mainnet" : "testnet";
          const explorerLink = `https://explorer.aptoslabs.com/txn/${Hash}?network=${explorerNetwork}`;
          toast.success(
            <div>
              Withdraw successfully
              <button
                onClick={() => window.open(explorerLink, "_blank")}
                className="explorer-link-button"
              >
                Click to see in explorer
              </button>
            </div>
          );
          setIsLoading(false);
        } else {
          // Handle the scenario where Hash is undefined or empty
          toast.error("Transaction hash is invalid or empty");
          toast.dismiss(loading);
        }
      } else {
        toast.error(data.message); // Display error message received from withdrawDetails
        toast.dismiss(loading);
      }
    } catch (error) {
      toast.error("User rejected the deposit");
      toast.dismiss(loading);
      setIsLoading(false);
    }
  };

  // const handleFeePayerClick = (selectedFeePayer: any) => {
  //   if (selectedFeePayer !== selectedFeePayer) {
  //     updateFeePayerAddresses(selectedFeePayer);
  //     toggleDropdown();
  //   }
  //   toggleDropdown();
  // };

  const handleClose = () => {
    setIsModalOpen(false);
    handleCloseDepositModel();
    toast.dismiss(loading);
  };
  // const handleClickAway = () => {
  //   setIsModalOpen(false);
  //   // navigate("/");
  // };

  const handleTabChange = (tab: any) => {
    setSelectedTab(tab);
  };

  const getFeePayerAccountBalance = async () => {
    const getBalance = await checkAptBalance(
      feePayerAddresses[0],
      "mainnet"
    );
    setGetFeePayerBalance(getBalance / 100000000);
  };


  useEffect(() => {
    getFeePayerAccountBalance();
    canAbleToWithdraw();
  }, [feePayerAddresses[0]]);

  return (
    <div
      className={` !overflow-x-hidden  bg-[#0C0C0D]  min-h-screen h-full font-inter flex flex-row justify-start items-start w-screen xxl:!gap-[1rem] bxl:!gap-[1rem] xl:!gap-[1rem] sxl:!gap-[1rem] `}
    >
      <div className=" xxl:inline bxl:inline xl:inline sxl:inline lg:hidden md:hidden sm:hidden xd:hidden w-[5%]  ">
        <Sidebar />
      </div>
      <div className="w-full flex flex-row justify-center items-center ">
        <div className="flex flex-col  xxl:!w-[1600px] bxl:!w-[1600px] xl:!w-[90%] sxl:!w-[95.5%] lg:!w-[96%] md:!w-[100%] sm:!w-[100%] xd:!w-[100%]    ">
          <Header />
          {isModalOpen && (
            <div className=" font-manrope !fixed !z-[2] inset-0 h-full w-full bg-[rgba(0,0,0,0.20)] backdrop-blur-[0.75rem] flex flex-row justify-center items-center">
              {/* <ClickAwayListener onClickAway={handleClickAway}> */}
              <div className="flex">
                {/* <div className="w-[35rem] h-[25rem] rounded-[1rem] border-2 border-[#ffffff1a] bg-[#17181A]">
                <div className="flex p-4 border-b-2 border-[#ffffff1a]">
                  <div className="bg-[#0C0C0D] p-[1rem] rounded-[1rem] w-[2.5rem] h-[2.5rem] flex justify-center items-center text-[1rem] font-[800] text-[#FFFFFF]">
                    1.
                  </div>
                  <div className=" pl-3">
                    {network?.name?.toLowerCase() === "mainnet" ? (
                      <>
                        <div className="text-[1.125rem] text-[#FFFFFFCC] font-[800]">
                          Choose Deposit mode
                        </div>
                        <div className=" flex text-[0.875rem]">
                          <div className="text-[#A5A5A6] font-[700] w-[9.875rem]">
                            Deposit:
                          </div>
                          <div className="text-[#A5A5A6] font-[400] pl-2 ">
                            Distributes the fund to fee-payer account equally
                          </div>
                        </div>
                        <div className=" flex text-[0.875rem]">
                          <div className="text-[#A5A5A6] font-[700] w-[9.875rem]">
                            Deposit to specific:
                          </div>
                          <div className="text-[#A5A5A6] font-[400]">
                            Deposit to a particular fee-payer account
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="text-[1.125rem] text-[#FFFFFFCC] font-[800]">
                          Choose Deposit mode
                        </div>
                        <div className=" flex text-[0.875rem]">
                          <div className="text-[#A5A5A6] font-[700] w-[9.875rem]">
                            Deposit to specific:
                          </div>
                          <div className="text-[#A5A5A6] font-[400]">
                            Deposit to a particular fee-payer account
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
                <div className="flex p-4 border-b-2 border-[#ffffff1a]">
                  <div className="bg-[#0C0C0D] p-[1rem] rounded-[1rem] w-[2.5rem] h-[2.5rem] flex justify-center items-center text-[1rem] font-[800] text-[#FFFFFF]">
                    2.
                  </div>
                  <div className="pl-3">
                    <div className="text-[#FFFFFFCC] font-[800] text-[1.125rem]">
                      Enter the amount to deposit
                    </div>
                    <div className="text-[#A5A5A6] text-[0.875rem] font-[400]">
                      For example, if you want to deposit 0.1 APT - enter 0.1
                    </div>
                    <div className="text-[#A5A5A6] text-[0.875rem] font-[400]">
                      While you have the flexibility to deposit any amount, we
                      highly recommend a deposit of 1 APT to the fee-payer
                    </div>
                  </div>
                </div>
                <div className="flex p-4">
                  <div className="bg-[#0C0C0D] p-[1rem] rounded-[1rem] w-[2.5rem] h-[2.5rem] flex justify-center items-center text-[1rem] font-[800] text-[#FFFFFF]">
                    3.
                  </div>
                  <div className=" pl-3">
                    <div className="text-[#FFFFFFCC] font-[800] text-[1.125rem]">
                      Click Deposit
                    </div>
                    <div className="text-[#A5A5A6] text-[0.875rem] font-[400]">
                      Once the deposit is confirmed, the amount will be
                      deposited from your wallet to corresponding fee-payer
                      accounts
                    </div>
                  </div>
                </div>
              </div> */}
                <div className="w-[35rem] rounded-[1rem] border-2 border-[#ffffff1a] bg-[#121415] ml-2">
                  <div className="">
                    <Tabs>
                      <TabList className=" bg-[#ffffff0f] h-[4.5rem] rounded-t-[1rem] flex justify-between text-[#FFFFFF] text-[1.125rem]">
                        <div className=" flex justify-normal">
                          <Tab
                            className={`cursor-pointer outline-none Tab_container p-4 w-full ${selectedTab === "deposit" ? "border-b-2" : ""
                              }`}
                            onClick={() => handleTabChange("deposit")}
                          >
                            Deposit
                          </Tab>
                          <Tab
                            className={`cursor-pointer outline-none Tab_container p-4 w-full ${selectedTab === "withdrawal" ? "border-b-2" : ""
                              }`}
                            onClick={() => handleTabChange("withdrawal")}
                          >
                            Withdraw
                          </Tab>
                        </div>
                        <img
                          src={Xclose}
                          alt="Xclose"
                          className=" cursor-pointer p-4"
                          onClick={handleClose}
                        />
                      </TabList>

                      <TabPanel>
                        <div>
                          <div className="text-[#FFFFFFCC] font-[400] font-inter text-[0.875rem] px-7 pt-3 pb-2 flex justify-between">
                            <span>Fee-payer address</span>
                            <span className="flex gap-[0.25rem] items-center text-[#A5A5A6] text-[0.625rem] font-[400]">
                              Balance{" "}
                              <span className="text-[#FFFFFFCC] font-[700]">
                                {getFeePayerBalance} {"APT"}
                              </span>
                            </span>
                          </div>
                          <div className=" flex justify-center">
                            <div className="w-[32rem] h-[4rem] border-[#ffffff1a] rounded-[1rem] border-2 font-[800] font-manrope flex justify-between p-2 items-center pl-4 text-[#FFFFFFCC]">
                              <div>
                                {feePayerAddresses[0] && (
                                  <>
                                    {feePayerAddresses[0].substring(0, 18)}
                                    ...
                                    {feePayerAddresses[0].substring(
                                      feePayerAddresses[0].length - 18
                                    )}
                                  </>
                                )}
                              </div>
                              <img
                                src={Copy}
                                alt="Copy"
                                className="cursor-pointer w-4 h-4"
                                onClick={() => copyAddress(feePayerAddresses[0])}
                              />
                            </div>
                          </div>
                          <div className="text-[#FFFFFFCC] font-[400] font-inter text-[0.875rem] px-7 py-2  ">
                            Amount to be deposited
                          </div>
                          <div className="flex justify-center">
                            <div className=" w-[32rem] h-[7rem] rounded-[1rem] border-2 border-[#ffffff1a] text-[#FFFFFFCC] font-[800] font-manrope">
                              <div className=" flex justify-between w-[30rem] border-b-2 border-[#ffffff1a] p-3 ml-3">
                                <div className="text-[#777879] text-[]">APT</div>
                                <input
                                  type="text"
                                  placeholder="0.00"
                                  className="outline-0 border-0 h-full bg-transparent  text-right font-manrope w-[25rem] text-[#FFFFFFCC] text-[1.25rem] font-[800] outline-none"
                                  value={amount}
                                  onChange={(e) => {
                                    const input = e.target.value.replace(
                                      /[^0-9.]/g,
                                      ""
                                    );
                                    if (input === "") {
                                      setAmount("");
                                    } else if (input === ".") {
                                      setAmount("0.");
                                    } else {
                                      setAmount(input);
                                    }
                                  }}
                                />
                              </div>
                              <div className=" flex flex-row justify-between text-[0.625rem] items-center py-[1rem] px-[1.5rem] ">
                                <div className="flex text-[#A5A5A6] font-[400]">
                                  <div>Wallet Balance</div>
                                  <div className="text-[#FFFFFFCC] font-[700] pl-1">
                                    {`${balance !== undefined ? `${balance}` : `${0}`
                                      }`}
                                  </div>
                                  <div className="pl-1">APT</div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="flex justify-center my-[0.8rem]">
                            <button
                              className="buy-button w-[32rem]"
                              onClick={handleDepositClick}
                            >
                              <div className="buy-button-inner">
                                <span className=" text-[#2ED3B7] font-[800]">
                                  Deposit
                                </span>
                              </div>
                            </button>
                          </div>
                        </div>
                      </TabPanel>
                      <TabPanel>
                        <div>
                          <div className="text-[#FFFFFFCC] font-[400] font-inter text-[0.875rem] px-7 pt-3 pb-2 flex justify-between">
                            <span>Fee-payer address</span>
                            <span className="flex gap-[0.25rem] items-center text-[#A5A5A6] text-[0.625rem] font-[400]">
                              Balance{" "}
                              <span className="text-[#FFFFFFCC] font-[700]">
                                {getFeePayerBalance} {"APT"}
                              </span>
                            </span>
                          </div>
                          <div className=" flex justify-center">
                            <div className="w-[32rem] h-[4rem] border-[#ffffff1a] rounded-[1rem] border-2 font-[800] font-manrope flex justify-between p-2 items-center pl-4 text-[#FFFFFFCC]">
                              <div className="truncate">
                                {feePayerAddresses[0] && (
                                  <>
                                    {feePayerAddresses[0].substring(0, 18)}
                                    ...
                                    {feePayerAddresses[0].substring(
                                      feePayerAddresses[0].length - 18
                                    )}
                                  </>
                                )}
                              </div>
                              <img
                                src={Copy}
                                alt="Copy"
                                className="cursor-pointer w-4 h-4"
                                onClick={() => copyAddress(feePayerAddresses[0])}
                              />
                            </div>
                          </div>

                          <div className="text-[#FFFFFFCC] font-[400] font-inter text-[0.875rem] px-7 py-2  ">
                            Amount to Withdraw
                          </div>

                          <div className="flex justify-center">
                            <div className=" w-[32rem] h-[7rem] rounded-[1rem] border-2 border-[#ffffff1a] text-[#FFFFFFCC] font-[800] font-manrope">
                              <div className=" flex justify-between w-[30rem] border-b-2 border-[#ffffff1a] p-3 ml-3">
                                <div className="text-[#777879] text-[]">APT</div>
                                <input
                                  type="text"
                                  placeholder="0.00"
                                  className="outline-0 border-0 h-full bg-transparent  text-right font-manrope w-[25rem] text-[#FFFFFFCC] text-[1.25rem] font-[800] outline-none"
                                  value={amount}
                                  onChange={(e) => {
                                    const input = e.target.value.replace(
                                      /[^0-9.]/g,
                                      ""
                                    );
                                    if (input === "") {
                                      setAmount("");
                                    } else if (input === ".") {
                                      setAmount("0.");
                                    } else {
                                      setAmount(input);
                                    }
                                  }}
                                />
                              </div>
                              <div className=" flex flex-row justify-between text-[0.625rem] items-center py-[1rem] px-[1.5rem] ">
                                <div className="flex text-[#A5A5A6] font-[400]">
                                  <div>Wallet Balance</div>
                                  <div className="text-[#FFFFFFCC] font-[700] pl-1">
                                    {`${balance !== undefined ? `${balance}` : `${0}`
                                      }`}
                                  </div>
                                  <div className="pl-1">APT</div>
                                </div>
                                <div className="flex text-[#A5A5A6] font-[400]">
                                  {/* <div className="font-[700]">{totalUSDPrice}</div> */}
                                  {/* <div className="pl-1">USD</div> */}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="text-[#F04438] text-[0.75rem] font-[400] font-inter text-[0.875rem] px-7 py-2 ">
                            You can withdraw upto{" "}
                            {(howMuchCanWithdraw / 100000000).toFixed(8)} APT
                          </div>
                          <div className="flex justify-center my-[0.8rem]">
                            <button
                              className="buy-button w-[32rem]"
                              onClick={handleWithdrawClick}
                            >
                              <div className="buy-button-inner">
                                <span className=" text-[#2ED3B7] font-[800]">
                                  Withdraw now
                                </span>
                              </div>
                            </button>
                          </div>
                        </div>
                      </TabPanel>
                    </Tabs>
                  </div>
                </div>
              </div>
              {/* </ClickAwayListener> */}
            </div>
          )}
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default Deposit;
