import React, { useState } from 'react';
import Kana from "../assets/icons/kana_logo.svg";
import Menu from '../assets/icons/menu.svg';
import aptos from '../assets/icons/paymaster_aptos.png';
import ChevronDown from '../assets/icons/chevron-down.png';
const Webflow = () => {
    const [isDropdown, setIsDropdown] = useState<number | null>(null);
    const handleDropdown = (index: any) => {
        if (isDropdown === index) {
            // If the same dropdown is clicked again, close it
            setIsDropdown(null);
        } else {
            // Otherwise, open the clicked dropdown
            setIsDropdown(index);
        }
    }
    const [isOpenSidebar, setIsOpenSidebar] = useState(false);
    const handleSidebar = () => {
        setIsOpenSidebar(!isOpenSidebar);
    }
    return (
        <div className="  w-full h-full flex flex-col justify-start items-start  ">
            <div className="p-[24px]   h-[5.5rem] fixed top-0  z-[2]  backdrop-blur-[4rem] flex flex-row justify-between items-center w-full">
                <img src={Kana} alt="Kana" />
                <div className="flex flex-row items-center gap-[1.5rem] justify-center   w-auto   ">
                    <a href='https://docs.kanalabs.io/paymaster-service/kana-paymaster-for-aptos' target='_blank'>
                        <div className=" cursor-pointer xxl:flex bxl:flex xl:flex sxl:flex lg:flex md:hidden sm:hidden xd:hidden   text-[#2ED3B7] text-[1rem] font-manrope font-[800] ">
                            Documentation
                        </div>
                    </a>
                    <a href='/dashboard'>
                        <div className=" cursor-pointer   xxl:flex bxl:flex xl:flex sxl:flex lg:flex md:hidden sm:hidden xd:hidden  text-[#2ED3B7] text-[1rem] font-manrope font-[800] ">
                            Dashboard
                        </div>
                    </a>
                    <a href='/grant'>
                        <div className=" cursor-pointer   xxl:flex bxl:flex xl:flex sxl:flex lg:flex md:hidden sm:hidden xd:hidden  text-[#2ED3B7] text-[1rem] font-manrope font-[800] ">
                            Grant
                        </div>
                    </a>
                    <img src={Menu} className='xxl:hidden bxl:hidden xl:hidden sxl:hidden lg:hidden md:flex sm:flex xd:flex mr-3' alt="" onClick={handleSidebar} />
                </div>
            </div>
            {isOpenSidebar &&
                <div className='fixed z-[2] p-[2rem] xxl:hidden bxl:hidden xl:hidden sxl:hidden lg:hidden md:flex sm:flex xd:flex  flex flex-col justify-start items-start gap-[3rem] w-full h-full left-0 right-0 bottom-0 top-[5.5rem] backdrop-blur-[4rem] '>
                    <a href='https://docs.kanalabs.io/paymaster-service/kana-paymaster-for-aptos' target='_blank'>
                        <div className="    text-[#2ED3B7] text-[1rem] font-manrope font-[800] ">
                            Documentation
                        </div>
                    </a>
                    <a href='/dashboard'>
                        <div className="    text-[#2ED3B7] text-[1rem] font-manrope font-[800] ">
                            Dashboard
                        </div>
                    </a>
                    <a href='/grant'>
                        <div className="    text-[#2ED3B7] text-[1rem] font-manrope font-[800] ">
                            Grant
                        </div>
                    </a>
                </div>
            }
            <div className='bg-blur_bg_paymaster h-full w-full xxl:px-[32rem] bxl:px-[28rem] xl:px-[22rem] sxl:px-[10rem] lg:px-[10rem] md:px-[7rem] sm:px-[1rem] xd:px-[1rem]  bg-center  bg-cover bg-no-repeat'>
                <div className="flex text-[white] pt-[9rem]   h-full flex-col justify-center items-center text-center   font-urbanist text-[5.25rem] sm:text-[2.5rem] xd:text-[2.5rem] font-[800]  ">
                    <div>KANALABS PAYMASTER</div>
                </div>
                <div className='flex xxl:flex-row bxl:flex-row xl:flex-row sxl:flex-row lg:flex-row md:flex-col-reverse sm:flex-col-reverse xd:flex-col-reverse justify-between py-[4rem] items-center w-full xxl:px-[0rem] bxl:px-[0rem] xl:px-[0rem] sxl:px-[0rem] lg:px-[1rem] md:px-[1rem] sm:px-[0rem] xd:px-[0rem]'>
                    <div className='flex-[0.6] font-manrope p-[0.5rem] text-[0.75rem] font-[400] text-[#777879] flex flex-col justify-start items-start gap-[1.5rem]'>
                        <div>Kana Labs Paymaster has been live on the Aptos Network for some time now. As the first and largest Paymaster service in the Aptos ecosystem today, we have been live on the mainnet for four months.</div>
                        <div>Kana Labs Paymaster created specifically for the Aptos network based on  <span className=' font-[800] text-[#2ED3B7]  pl-[0.125rem]'>AIP-39</span>, has been battle-tested and proven in the mainnet, Sponsoring more than 15 Million transactions for approximately 3 Million users across multiple dApps in SocialFi & DeFi markets.</div>
                    </div>
                    <div className='mix-blend-screen flex-[0.4]' >
                        <img className='mix-blend-screen' src={aptos} alt='' />
                    </div>
                </div>
                <div className='flex xxl:flex-row bxl:flex-row xl:flex-row sxl:flex-row lg:flex-row md:flex-col sm:flex-col xd:flex-col justify-between items-center gap-[0.5rem]'>
                    <div className='xxl:w-[50%] bxl:w-[50%] xl:w-[50%] sxl:w-[50%] lg:w-[100%] md:w-[100%] sm:w-[100%] xd:w-[100%] border-[1px]  rounded-[1rem] backdrop-[1.25rem] flex flex-col gap-[1rem] bg-[rgba(23,24,26,0.50)] p-[1rem] border-[rgba(255,255,255,0.10)]'>
                        <div className='  rounded-[0.5rem] bg-gradient-to-b p-[1px] from-[#1FF8EB] to-[#01A4FF]'>
                            <div className=' flex flex-row justify-center font-urbanist text-[2.25rem] font-[700] py-[5rem] items-center rounded-[0.5rem] bg-no-repeat bg-cover bg-dashboard_bg w-full h-full'>
                                <div className='bg-clip-text text-transparent bg-gradient-to-r from-[#1FF8EB] to-[#01A4FF]'> Dashboard</div>
                            </div>
                        </div>
                        <a href='/dashboard'>
                            <button
                                className="buy-button w-full " >
                                <div className="buy-button-inner !h-[56px]  text-[#2ED3B7] text-[1rem] font-manrope font-[800] ">
                                    Visit Dashboard
                                </div>
                            </button>
                        </a>
                    </div>
                    <div className='xxl:w-[50%] bxl:w-[50%] xl:w-[50%] sxl:w-[50%] lg:w-[100%] md:w-[100%] sm:w-[100%] xd:w-[100%]  border-[1px]  rounded-[1rem] backdrop-[1.25rem] flex flex-col gap-[1rem] bg-[rgba(23,24,26,0.50)] p-[1rem] border-[rgba(255,255,255,0.10)]'>
                        <div className='  rounded-[0.5rem] bg-gradient-to-b p-[1px] from-[#1FF8EB] to-[#01A4FF]'>
                            <div className=' flex flex-row justify-center font-urbanist text-[2.25rem] font-[700] py-[5rem] items-center rounded-[0.5rem] bg-no-repeat bg-cover bg-dashboard_bg w-full h-full'>
                                <div className='bg-clip-text text-transparent bg-gradient-to-r from-[#1FF8EB] to-[#01A4FF]'> Grant</div>
                            </div>
                        </div>
                        <a href='/grant'>
                            <button
                                className="buy-button w-full " >
                                <div className="buy-button-inner !h-[56px]  text-[#2ED3B7] text-[1rem] font-manrope font-[800] ">
                                    Grant details
                                </div>
                            </button>
                        </a>
                    </div>
                </div>
                <div className='py-[4rem] flex flex-col gap-[0.5rem] w-full'>
                    <div className='text-[rgba(255,255,255,0.80)] font-manrope text-[0.875rem] font-[800] '>Try it now:</div>
                    <div className="flex flex-row justify-center items-center w-full  ">
                        <div className="bg-[#17181A] gap-[1rem] flex xxl:flex-row bxl:flex-row xl:flex-row sxl:flex-row lg:flex-row md:flex-col sm:flex-col xd:flex-col justify-start items-start border-[1px] border-[rgba(255,255,255,0.10)] p-[1rem] rounded-[1rem] w-full ">
                            <div className="text-[1rem]  font-source_code_pro font-[400] text-[#D2D2D2] p-[1rem] border-[1px] rounded-[1rem] bg-[#111213] border-[rgba(255,255,255,0.10)] xxl:w-[50%] bxl:w-[50%] xl:w-[50%] sxl:w-[50%] lg:w-[100%] md:w-[100%] sm:w-[100%] xd:w-[100%]">
                                npm install @kanalabs/paymaster-sdk
                                <br />
                                or
                                <br />
                                yarn add @kanalabs/paymaster-sdk
                            </div>
                            <div className="text-[1rem] flex flex-col gap-[0.5rem] justify-start items-start font-manrope font-[400] text-[rgba(255,255,255,0.89)] xxl:w-[50%] bxl:w-[50%] xl:w-[50%] sxl:w-[50%] lg:w-[100%] md:w-[100%] sm:w-[100%] xd:w-[100%]">
                                Start implementing Paymaster now
                                <div
                                    className="text-[#2ED3B7] text-left cursor-pointer font-manrope font-[800] text-[0.75rem] border-[1px] border-[#2ED3B7] rounded-[0.5rem] p-[0.5rem_1rem]  "
                                    onClick={() => {
                                        window.open(
                                            "https://docs.kanalabs.io/paymaster-service/kana-paymaster-for-aptos",
                                            "_blank"
                                        );
                                    }} >
                                    Read Documentation
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className='pb-[4rem] flex flex-col gap-[0.5rem] w-full'>
                    <div className='text-[rgba(255,255,255,0.80)] font-manrope text-[0.875rem] font-[800] '>FAQ:</div>
                    <div className='flex flex-row gap-[0.5rem] justify-start items-start w-full'>
                        <div className="flex flex-col justify-start items-start w-full gap-[0.5rem] ">
                            <div className="bg-[#17181A] flex-[0.5] gap-[0.5rem] flex flex-col justify-start items-start border-[1px] border-[rgba(255,255,255,0.10)] p-[1rem] rounded-[1rem] w-full ">
                                <div className='flex flex-row justify-between items-center w-full'>
                                    <div className='text-[rgba(255,255,255,0.80)] font-[400]  font-manrope text-[1rem]'>What is Kana Labs Paymaster?</div>
                                    <div onClick={() => handleDropdown(1)} className='cursor-pointer'><img className={`${isDropdown == 1 ?'rotate-180':''}`} src={ChevronDown} alt='' /></div>
                                </div>
                                {isDropdown == 1 && <div className='text-[0.75rem] font-manrope font-[400] text-[#777879]'>
                                    Kana Labs Paymaster has been live on the Aptos Network for some time now. As the first and largest Paymaster service in the Aptos ecosystem today, we have been live on the mainnet for four months.
                                </div>}
                            </div>
                            <div className="bg-[#17181A] flex-[0.5] gap-[0.5rem] flex flex-col justify-start items-start border-[1px] border-[rgba(255,255,255,0.10)] p-[1rem] rounded-[1rem] w-full ">
                                <div className='flex flex-row justify-between items-center w-full'>
                                    <div className='text-[rgba(255,255,255,0.80)] font-[400]  font-manrope text-[1rem]'>How to implement it?</div>
                                    <div onClick={() => handleDropdown(2)} className='cursor-pointer'><img className={`${isDropdown == 2 ?'rotate-180':''}`} src={ChevronDown} alt='' /></div>
                                </div>
                                {isDropdown == 2 && <div className='text-[0.75rem] font-manrope font-[400] text-[#777879]'>
                                    Kana Labs Paymaster has been live on the Aptos Network for some time now. As the first and largest Paymaster service in the Aptos ecosystem today, we have been live on the mainnet for four months.
                                </div>}
                            </div>
                        </div>
                        <div className="flex flex-col justify-start items-start w-full gap-[0.5rem] ">
                            <div className="bg-[#17181A] flex-[0.5] gap-[0.5rem] flex flex-col justify-start items-start border-[1px] border-[rgba(255,255,255,0.10)] p-[1rem] rounded-[1rem] w-full ">
                                <div className='flex flex-row justify-between items-center w-full'>
                                    <div className='text-[rgba(255,255,255,0.80)] font-[400]  font-manrope text-[1rem]'>Where to use Kana Labs Paymaster?</div>
                                    <div onClick={() => handleDropdown(3)} className='cursor-pointer'><img className={`${isDropdown == 3 ?'rotate-180':''}`} src={ChevronDown} alt='' /></div>
                                </div>
                                {isDropdown == 3 && <div className='text-[0.75rem] font-manrope font-[400] text-[#777879]'>
                                    Kana Labs Paymaster has been live on the Aptos Network for some time now. As the first and largest Paymaster service in the Aptos ecosystem today, we have been live on the mainnet for four months.
                                </div>}
                            </div>
                            <div className="bg-[#17181A] flex-[0.5] gap-[0.5rem] flex flex-col justify-start items-start border-[1px] border-[rgba(255,255,255,0.10)] p-[1rem] rounded-[1rem] w-full ">
                                <div className='flex flex-row justify-between items-center w-full'>
                                    <div className='text-[rgba(255,255,255,0.80)] font-[400]  font-manrope text-[1rem]'>How to contact the devs?</div>
                                    <div onClick={() => handleDropdown(4)} className='cursor-pointer'><img className={`${isDropdown == 4 ?'rotate-180':''}`} src={ChevronDown} alt='' /></div>
                                </div>
                                {isDropdown == 4 && <div className='text-[0.75rem] font-manrope font-[400] text-[#777879]'>
                                    Kana Labs Paymaster has been live on the Aptos Network for some time now. As the first and largest Paymaster service in the Aptos ecosystem today, we have been live on the mainnet for four months.
                                </div>}
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>

        </div>
    );
}

export default Webflow;
