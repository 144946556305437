import React, { useCallback, useEffect, useMemo } from "react";
import { useState } from "react";
import axios from "axios";
import { useWallet } from "@aptos-labs/wallet-adapter-react";
import { isValidAddressFormat } from "./Register";
import { toast } from "react-toastify";
import { useStore } from "store";
import ConnectWallet from "./ConnectWallet";
import { useNavigate } from "react-router-dom";
import { Switch } from "antd";
import Lottie from "react-lottie-player";
import Kanaloader from "../assets/kanaloader.json";
import Pagination from "../pagination/Pagination";
import Sidebar from "./Sidebar";
import Header from "./Header";
import Footer from "./Footer";

const UserWhitelist = () => {
  const navigate = useNavigate();
  const { connected } = useWallet();
  const { apikey, updateWalletConnect, iswalletConnect } = useStore();
  const [address, setAddress] = useState<string>("");
  const [data, setData] = useState<any>();
  const [isLoading, setIsLoading] = useState(true);
  const [addWhitelist, setAddWhitelist] = useState(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [whitelistedAddress, setWhitelistedAddress] = useState<any[]>([]);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [itemsPerPage, setItemsPerPage] = useState(7);

  let loading: any;
  const getWhitelistedUsers = useCallback(
    async (page?: any) => {
      if (connected) {
        updateWalletConnect(false);
        if (apikey) {
          const take = 10; // Number of records to fetch per request
          const skip = (page - 1) * take; // Calculate skip based on the current page

          try {
            const getWhitelistedUsersUrl = `${process.env.REACT_APP_BASE_URL}/getWhitelistedUsers`;
            const response = await axios.get(getWhitelistedUsersUrl, {
              headers: {
                "Content-Type": "application/json",
                "api-key": apikey as string,
              },
              params: {
                skip: skip.toString(), // Convert skip to string
                take: take.toString(),
              },
            });

            if (response.status === 200) {
              const { users } = response.data;
              setIsLoading(false);
              if (users.length === 0) {
                setIsLoading(false);
              } else {
                setWhitelistedAddress((prevWhitelistedAddress) => {
                  if (page === currentPage) {
                    const existingAddresses = prevWhitelistedAddress.map(
                      (user) => user.address
                    );

                    const newUsers = users.filter(
                      (user: any) => !existingAddresses.includes(user.address)
                    );

                    return [...prevWhitelistedAddress, ...newUsers];
                  } else {
                    const existingAddresses = prevWhitelistedAddress.map(
                      (user) => user.address
                    );

                    const newUsers = users.filter(
                      (user: any) => !existingAddresses.includes(user.address)
                    );
                    return [...prevWhitelistedAddress, ...newUsers];
                  }
                });
              }
            }
          } catch (error: any) {
            toast.error(error.response?.data?.error);
            setIsLoading(false);
          }
        } else {
          toast.info("Please register");
          setIsLoading(false);
        }
      } else {
        toast.warning("Please connect to wallet");
        setIsLoading(false);
      }
    },
    [apikey, connected, currentPage, updateWalletConnect]
  );

  const addToWhiteList = async () => {
    if (connected) {
      updateWalletConnect(false);
      if (apikey) {
        try {
          const mappedData = whitelistedAddress.map((item: any) => ({
            id: item.id,
            address: item.address,
            isActive: item.is_active,
            dappId: item.dapp_id,
          }));

          setData(mappedData);

          const gas_usage_limit = 100000000;
          const url = `${process.env.REACT_APP_BASE_URL}/addToWhitelist`;
          const data = {
            user_address: address,
            gas_usage_limit: gas_usage_limit,
          };

          if (
            !(address && address.length === 66 && isValidAddressFormat(address))
          ) {
            toast.warning("Invalid address");
          } else {
            loading = toast.loading("Loading...");
            const response = await axios.get(url, {
              params: data,
              headers: {
                "Content-Type": "application/json",
                "api-key": apikey as string,
              },
            });

            if (response.status === 201) {
              toast.dismiss(loading);
              toast.success("Successfully whitelisted");
              await getWhitelistedUsers(currentPage);
              handleCloseModal();
            }
          }
        } catch (error: any) {
          toast.dismiss(loading);
          toast.error(error.response.data.error);
        }
      } else {
        toast.dismiss(loading);
        toast.info("Please register");
      }
    } else {
      toast.dismiss(loading);
      toast.warning("Please connect to wallet");
    }
  };

  const handleUserStateEnable = async (userAddress: string) => {
    if (connected) {
      updateWalletConnect(false);
      if (apikey) {
        try {
          loading = toast.loading("Loading...");
          const dataparams = {
            user_address: userAddress,
            status: "true",
          };
          const modifyUserState = `${process.env.REACT_APP_BASE_URL}/modifyUserState`;
          const response = await axios.get(modifyUserState, {
            params: dataparams,
            headers: {
              "Content-Type": "application/json",
              "api-key": apikey as string,
            },
          });

          if (response.status === 200) {
            toast.dismiss(loading);
            toast.success("Successfully Enabled");
            // Update the local state immediately after toggling the user state
            setWhitelistedAddress((prevWhitelistedAddress) =>
              prevWhitelistedAddress.map((item) =>
                item.address === userAddress
                  ? { ...item, is_active: true }
                  : item
              )
            );
          }
        } catch (error: any) {
          toast.error(error.response.data.error);
        }
      } else {
        toast.info("Please register");
        setIsLoading(false);
      }
    } else {
      toast.warning("Please connect to wallet");
      setIsLoading(false);
    }
  };

  const handleUserStateDisable = async (userAddress: string) => {
    if (connected) {
      updateWalletConnect(false);
      if (apikey) {
        try {
          loading = toast.loading("Loading...");
          const dataparams = {
            user_address: userAddress,
            status: "false",
          };
          const modifyUserState = `${process.env.REACT_APP_BASE_URL}/modifyUserState`;
          const response = await axios.get(modifyUserState, {
            params: dataparams,
            headers: {
              "Content-Type": "application/json",
              "api-key": apikey as string,
            },
          });

          if (response.status === 200) {
            toast.dismiss(loading);
            toast.success("Successfully disabled");
            // Update the local state immediately after toggling the user state
            setWhitelistedAddress((prevWhitelistedAddress) =>
              prevWhitelistedAddress.map((item) =>
                item.address === userAddress
                  ? { ...item, is_active: false }
                  : item
              )
            );
          }
        } catch (error: any) {
          toast.error(error.response.data.error);
          setIsLoading(false);
        }
      } else {
        toast.info("Please register");
        setIsLoading(false);
      }
    } else {
      toast.warning("Please connect to wallet");
      setIsLoading(false);
    }
  };

  const handleCloseModal = () => {
    setAddWhitelist(false);
    setAddress("");
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentTableData = useMemo(() => {
    return whitelistedAddress.slice(startIndex, endIndex);
  }, [whitelistedAddress, startIndex, endIndex]);

  const handlePageChange = (page: any) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    if (currentPage !== 0) {
      getWhitelistedUsers(currentPage);
    }
  }, [currentPage, getWhitelistedUsers]);

  useEffect(() => {
    const totalPages = Math.ceil(whitelistedAddress.length / itemsPerPage);
    setTotalPage(totalPages);
  }, [whitelistedAddress, itemsPerPage]);

  return (
    <div
      className={` !overflow-x-hidden  bg-[#0C0C0D]  min-h-screen h-full font-inter flex flex-row justify-start items-start w-screen xxl:!gap-[1rem] bxl:!gap-[1rem] xl:!gap-[1rem] sxl:!gap-[1rem] `}
    >
      <div className=" xxl:inline bxl:inline xl:inline sxl:inline lg:hidden md:hidden sm:hidden xd:hidden w-[5%]  ">
        <Sidebar />
      </div>
      <div className="w-full flex flex-row justify-center items-center ">
        <div className="flex flex-col  xxl:!w-[1600px] bxl:!w-[1600px] xl:!w-[90%] sxl:!w-[95.5%] lg:!w-[96%] md:!w-[100%] sm:!w-[100%] xd:!w-[100%]    ">
          <Header />
          <div className="w-full mt-28">
            {isLoading && (
              <div
                className={`fixed inset-0 !z-[2]  backdrop-blur-2xl  font-inter  items-center overflow-y-auto  h-[100%] w-[100%] flex flex-col justify-start  bg-[black]   `}
              >
                <div className="flex justify-center align-middle pt-60 !bg-transparent">
                  <Lottie
                    className="!bg-transparent"
                    loop
                    animationData={Kanaloader}
                    play
                    style={{ width: 150, height: 150 }}
                  />
                </div>
                <div
                  className={`!bg-transparent text-center text-[0.875rem]  align-middle 2xl:pt-28 xl:pt-28 lg:pt-28 md:pt-10 sm:pt-4 max-sm:pt-4 text-[#FFFFFF] `}
                >
                  This may take few seconds, don’t close the window
                </div>
              </div>
            )}

            {!connected ? (
              <>{navigate("/dashboard")}</>
            ) : (
              <div className=" flex justify-center  my-3 font-manrope">
                <div className=" w-[75%] sm:w-full xd:w-full h-[47.75rem] rounded-[1rem] border-2 border-[#ffffff1a] sm:border-none xd:border-none bg-[#17181A] sm:bg-transparent  xd:bg-transparent flex flex-col justify-between">
                  <div>
                    <div className=" border-b-2 border-[#ffffff1a] h-[3.5rem] text-[0.875rem] text-[#A5A5A6] font-[800] p-3">
                      <div>Users Whitelists</div>
                    </div>
                    <div className=" gap-[1rem] flex flex-col justify-center items-center  align-middle p-3">
                      <div className=" flex flex-row justify-between w-full items-center h-[3.5rem] rounded-[1rem] bg-[#111213] gap-[1rem] border-[#ffffff1a] border-2 px-[1.5rem]">
                        <input
                          className="text-[16px] font-[600] text-[white] flex flex-row justify-start items-center w-full bg-transparent outline-none  "
                          placeholder="Enter address"
                          value={address}
                          onChange={(e) => setAddress(e.target.value)}
                        />
                        <div
                          className="hover:text-[#0C0C0D]  hover:bg-[#2ED3B7] text-center cursor-pointer  border-[1px] border-[#2ED3B7] bg-[transparent] text-[#2ED3B7]   text-[0.75rem] font-[800] gap-[8px] rounded-[0.5rem] p-[0.5rem_1rem] w-auto flex flex-row justify-center items-center"
                          onClick={() => addToWhiteList()}
                        >
                          Add
                        </div>
                      </div>
                      {whitelistedAddress && whitelistedAddress?.length > 0 ? (
                        currentTableData.map((item: any) => (
                          <div
                            className=" flex flex-row justify-between w-full items-center h-[3.5rem] rounded-[1rem] bg-[#111213] gap-[1rem] border-[#ffffff1a] border-2 px-[1.5rem] "
                            key={item?.id}
                          >
                            <div className=" text-[#FFFFFFCC] text-[1rem] font-[400] text-left xxl:inline-flex xl:inline-flex sxl:inline-flex bxl:inline-flex lg:inline-flex md:hidden sm:hidden xd:hidden">
                              {item?.address}
                            </div>
                            <div className="text-[#FFFFFFCC] text-[1rem] font-[400] text-left xxl:hidden xl:hidden sxl:hidden lg:hidden bxl:hidden md:inline-flex sm:inline-flex xd:inline-flex">
                              {item?.address?.slice(0, 5)}
                              {item?.address?.length > 10 && "..."}
                              {item?.address?.slice(-5)}
                            </div>

                            <div className="flex flex-row  gap-[1rem] items-center ">
                              <div className="flex-[1] justify-center flex flex-row  items-center ">
                                {item?.is_active === false ? (
                                  <div className="flex flex-row gap-[0.5rem] items-center text-[red] ">
                                    <div className="w-[0.5rem] h-[0.5rem] rounded-full bg-[red]"></div>
                                    InActive
                                  </div>
                                ) : (
                                  <div className="flex flex-row gap-[0.5rem] items-center text-[#2ED3B7] ">
                                    <div className="w-[0.5rem] h-[0.5rem] rounded-full bg-[#2ED3B7]"></div>
                                    Active
                                  </div>
                                )}
                              </div>
                              <div className=" pl-6">
                                <Switch
                                  onClick={() =>
                                    item.is_active
                                      ? handleUserStateDisable(item?.address)
                                      : handleUserStateEnable(item?.address)
                                  }
                                  checked={item?.is_active}
                                />
                              </div>
                            </div>
                          </div>
                        ))
                      ) : (
                        <div className="text-[#A5A5A6] font-[600]">
                          {whitelistedAddress &&
                            whitelistedAddress.length > 0 && (
                              <>No user addresses are whitelisted.</>
                            )}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className=" mb-8">
                    <div className=" flex justify-center xxl:pt-6 xl:pt-6 lg:pt-6 md:pt-6 sm:pt-3.5 xd:pt-3.5 text-[#697586] font-manrope font-[500] text-[0.875rem]">
                      Showing result {currentPage} of {totalPage}
                    </div>
                    <Pagination
                      currentPage={currentPage}
                      totalPages={totalPage}
                      onPageChange={handlePageChange}
                    />
                  </div>
                </div>
              </div>
            )}
            {iswalletConnect && <ConnectWallet />}
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default UserWhitelist;
