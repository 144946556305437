import React, { useEffect, useRef, useState } from "react";
import Chart from "chart.js/auto";
import { handleParticularUserData } from "utils/helpers";

const DoughnutChart: React.FC = () => {
  const [totalTxnCount1, setTotalTxnCount1] = useState<number>(0);
  const [signerAddress1, setSignerAddress1] = useState<string>("");
  const [totalTxnCount2, setTotalTxnCount2] = useState<number>(0);
  const [signerAddress2, setSignerAddress2] = useState<string>("");
  const [totalTxnCount3, setTotalTxnCount3] = useState<number>(0);
  const [signerAddress3, setSignerAddress3] = useState<string>("");
  const [totalTxnCount4, setTotalTxnCount4] = useState<number>(0);
  const [signerAddress4, setSignerAddress4] = useState<string>("");
  const [totalTxnCount5, setTotalTxnCount5] = useState<number>(0);
  const [signerAddress5, setSignerAddress5] = useState<string>("");
  const [totalTxnCount6, setTotalTxnCount6] = useState<number>(0);
  const [signerAddress6, setSignerAddress6] = useState<string>("");

  useEffect(() => {
    async function fetchData() {
      try {
        const totalDonutChartCount: any = await handleParticularUserData();
        setTotalTxnCount1(
          totalDonutChartCount?.[0]?.total_transactions_till_now || 0
        );
        setTotalTxnCount2(
          totalDonutChartCount?.[1]?.total_transactions_till_now || 0
        );
        setTotalTxnCount3(
          totalDonutChartCount?.[2]?.total_transactions_till_now || 0
        );
        setTotalTxnCount4(
          totalDonutChartCount?.[3]?.total_transactions_till_now || 0
        );
        setTotalTxnCount5(
          totalDonutChartCount?.[4]?.total_transactions_till_now || 0
        );
        setTotalTxnCount6(
          totalDonutChartCount?.[5]?.total_transactions_till_now || 0
        );
        setSignerAddress1(totalDonutChartCount?.[0]?.feePayer_name || "");
        setSignerAddress2(totalDonutChartCount?.[1]?.feePayer_name || "");
        setSignerAddress3(totalDonutChartCount?.[2]?.feePayer_name || "");
        setSignerAddress4(totalDonutChartCount?.[3]?.feePayer_name || "");
        setSignerAddress5(totalDonutChartCount?.[4]?.feePayer_name || "");
        setSignerAddress6(totalDonutChartCount?.[5]?.feePayer_name || "");
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, []);

  const chartRef = useRef<HTMLCanvasElement | null>(null);
  const [chart, setChart] = useState<Chart<
    "doughnut",
    number[],
    string
  > | null>(null);
  useEffect(() => {
    if (chart) {
      chart.destroy(); // Destroy existing chart instance if it exists
    }
    if (chartRef.current) {
      const ctx = chartRef.current.getContext("2d");
      if (ctx) {
        const newChartInstance = new Chart(ctx, {
          type: "doughnut",
          data: {
            labels: [
              "CHINGARI",
              "WAPAL",
              "KANASPOT",
              "TESTING",
              "ERAGON",
              "FLOC",
            ],
            datasets: [
              {
                data: [
                  totalTxnCount1,
                  totalTxnCount2,
                  totalTxnCount3,
                  totalTxnCount4,
                  totalTxnCount5,
                  totalTxnCount6,
                ],
                backgroundColor: [
                  "#00F9A9",
                  "#c66b27",
                  "#0E9384",
                  "#efe3e3",
                  "#FDB022",
                  "#431a69",
                ],
                borderColor: [
                  "#00F9A9",
                  "#c66b27",
                  "#0E9384",
                  "#efe3e3",
                  "#FDB022",
                  "#431a69",
                ],
                borderWidth: 1,
              },
            ],
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
          },
        });
        setChart(newChartInstance);
      }
    }
  }, [totalTxnCount1, totalTxnCount2, totalTxnCount3, totalTxnCount4, totalTxnCount5, totalTxnCount6]);

  const handleLabelClick = (index: number) => {
    if (chart) {
      const newData = [...chart.data.datasets[0].data];
      newData[index] += 10;
      chart.data.datasets[0].data = newData;
      chart.update();
    }
  };

  return (
    <div style={{ width: "408px", height: "208px" }}>
      <canvas ref={chartRef}></canvas>
    </div>
  );
};

export default DoughnutChart;
