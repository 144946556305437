import React, { useEffect, useState } from "react";
import ChevronDown from "../assets/icons/chevron-down.svg";
import DropDown from "../assets/icons/dropdown_arrow.svg";
import close from "../assets/icons/close.svg";
import ClickAwayListener from "react-click-away-listener";
import { Switch, Tooltip } from "antd";
import { useWallet } from "@aptos-labs/wallet-adapter-react";
import { useStore } from "store";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Lottie from "react-lottie-player";
import Kanaloader from "../assets/kanaloader.json";
import ConnectWallet from "./ConnectWallet";
import Information from "../assets/icons/information.svg";
import {
  addFunctions,
  handleDappDetails,
  fetchAccountModules,
  getWhiteListedFunctionsList,
  modifyAllowAllFunctionsStateToActive,
  modifyAllowAllFunctionsStateToInActive,
  modifyAllowAllScriptsStateToActive,
  modifyAllowAllScriptsStateToInActive,
  modifyFunctions,
  addmodule,
  getWhiteListedModulesList,
  modifymodules,
} from "utils/helpers";
import { Tabs, TabList, Tab, TabPanel } from "react-tabs";
import Sidebar from "./Sidebar";
import Header from "./Header";
import Footer from "./Footer";
const FunctionsWhitelist = () => {
  const navigate = useNavigate();
  const { connected, network } = useWallet();
  const {
    iswalletConnect,
    updateWalletConnect,
    apikey,
    address,
    signature,
    publicKey,
    updateAllowAllFunctions,
    updateAllowAllScripts,
    allowAllFunctions,
    allowAllScripts,
  } = useStore();
  const [isOpenFunction, setIsOpenFunction] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [responseData, setResponseData] = useState([]);
  const [selectedAbiIndex, setSelectedAbiIndex] = useState(-1);
  const [isOpenSubFunction, setIsOpenSubFunction] = useState(false);
  const [isAddFunctionPopup, setIsAddFunctionPopup] = useState(false);
  const [isaAddModulePopup, setIsAddModulePopup] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [whitelistedFunctions, setWhitelistedFunctions] = useState<any[]>([]);
  const [whitelistedModules, setWhitelistedModules] = useState<any[]>([]);
  const [isHovered, setIsHovered] = useState(false);
  const [selectedTab, setSelectedTab] = useState("deposit");

  const handleOpenFunction = () => {
    setIsOpenFunction(!isOpenFunction);
  };
  const handleWhitelistOpenSubFunction = (index: any) => {
    setIsOpenSubFunction(!isOpenSubFunction);
  };
  const handleOpenSubFunction = (index: any) => {
    setSelectedAbiIndex(selectedAbiIndex === index ? -1 : index);
  };
  const handleFunctionPopup = () => {
    setIsAddFunctionPopup(true);
  };
  const handleModulePopup = () => {
    setIsAddModulePopup(true);
  };
  const handleClickAway = () => {
    setInputValue("");
    setResponseData([]);
    isAddFunctionPopup && setIsAddFunctionPopup(false);
    isaAddModulePopup && setIsAddModulePopup(false);
  };

  const getAccountModules = async (address: any) => {
    if (connected) {
      updateWalletConnect(false);
      if (apikey) {
        setIsLoading(true);
        const response = await fetchAccountModules(address, apikey);
        setResponseData(response);
        setIsLoading(false);
      } else {
        toast.info("Please register");
        setIsLoading(false);
      }
    } else {
      toast.warning("Please connect to wallet");
      setIsLoading(false);
    }
  };

  const addFunctionWhitelist = async (
    moduleName: string,
    functionName: string,
    moduleAddress: string
  ) => {
    if (connected) {
      updateWalletConnect(false);
      if (apikey) {
        setIsLoading(true);
        await addFunctions(apikey, moduleName, functionName, moduleAddress);
        await getWhitelistedFunctions();
        setIsLoading(false);
      } else {
        toast.info("Please register");
        setIsLoading(false);
      }
    } else {
      toast.warning("Please connect to wallet");
      setIsLoading(false);
    }
  };

  const addmoduleWhitelist = async (moduleAddress: string) => {
    if (connected) {
      updateWalletConnect(false);
      if (apikey) {
        setIsLoading(true);
        await addmodule(apikey, moduleAddress);
        getWhitelistedModules()
        setIsLoading(false);
      } else {
        toast.info("Please register");
        setIsLoading(false);
      }
    } else {
      toast.warning("Please connect to wallet");
      setIsLoading(false);
    }
  };

  const getWhitelistedFunctions = async () => {
    if (connected) {
      updateWalletConnect(false);
      if (apikey) {
        setIsLoading(true);
        const response: any = await getWhiteListedFunctionsList(apikey);
        if (Array.isArray(response)) {
          setWhitelistedFunctions(response);
          setIsLoading(false);
        } else {
          setWhitelistedFunctions([]);
          setIsLoading(false);
        }
      } else {
        toast.info("Please register");
        setIsLoading(false);
      }
    } else {
      toast.warning("Please connect to wallet");
      setIsLoading(false);
    }
  };

  const getWhitelistedModules = async () => {
    if (connected) {
      updateWalletConnect(false);
      if (apikey) {
        setIsLoading(true);
        const response: any = await getWhiteListedModulesList(apikey);
        if (Array.isArray(response)) {
          setWhitelistedModules(response);
          setIsLoading(false);
        } else {
          setWhitelistedModules([]);
          setIsLoading(false);
        }
      } else {
        toast.info("Please register");
        setIsLoading(false);
      }
    } else {
      toast.warning("Please connect to wallet");
      setIsLoading(false);
    }
  };

  const modifyFunctionState = async (
    moduleName: string,
    functionName: string,
    moduleAddress: string,
    status: any
  ) => {
    if (connected) {
      updateWalletConnect(false);
      if (apikey) {
        setIsLoading(true);
        await modifyFunctions(
          apikey,
          moduleName,
          functionName,
          moduleAddress,
          status
        );
        await getWhitelistedFunctions();
        setIsLoading(false);
      } else {
        toast.info("Please register");
        setIsLoading(false);
      }
    } else {
      toast.warning("Please connect to wallet");
      setIsLoading(false);
    }
  };

  const modifyModulesState = async (moduleAddress: string, status: any) => {
    if (connected) {
      updateWalletConnect(false);
      if (apikey) {
        setIsLoading(true);
        await modifymodules(apikey, moduleAddress, status);
        await getWhitelistedModules();
        setIsLoading(false);
      } else {
        toast.info("Please register");
        setIsLoading(false);
      }
    } else {
      toast.warning("Please connect to wallet");
      setIsLoading(false);
    }
  };

  const modifyAllowAllFunctionsStateActive = async () => {
    if (connected) {
      updateWalletConnect(false);
      if (apikey) {
        setIsLoading(true);
        await getDappDetails();
        await modifyAllowAllFunctionsStateToActive(apikey);
        updateAllowAllFunctions(true);
        setIsLoading(false);
      } else {
        toast.info("Please register");
        setIsLoading(false);
      }
    } else {
      toast.warning("Please connect to wallet");
      setIsLoading(false);
    }
  };

  const modifyAllowAllFunctionsStateInActive = async () => {
    if (connected) {
      updateWalletConnect(false);
      if (apikey) {
        setIsLoading(true);
        await getDappDetails();
        await modifyAllowAllFunctionsStateToInActive(apikey);
        updateAllowAllFunctions(false);
        setIsLoading(false);
      } else {
        toast.info("Please register");
        setIsLoading(false);
      }
    } else {
      toast.warning("Please connect to wallet");
      setIsLoading(false);
    }
  };

  const modifyAllowAllScriptsStateActive = async () => {
    if (connected) {
      updateWalletConnect(false);
      if (apikey) {
        setIsLoading(true);
        await getDappDetails();
        await modifyAllowAllScriptsStateToActive(apikey);
        updateAllowAllScripts(true);
        setIsLoading(false);
      } else {
        toast.info("Please register");
        setIsLoading(false);
      }
    } else {
      toast.warning("Please connect to wallet");
      setIsLoading(false);
    }
  };

  const modifyAllowAllScriptsStateInActive = async () => {
    if (connected) {
      updateWalletConnect(false);
      if (apikey) {
        setIsLoading(true);
        await getDappDetails();
        await modifyAllowAllScriptsStateToInActive(apikey);
        updateAllowAllScripts(false);
        setIsLoading(false);
      } else {
        toast.info("Please register");
        setIsLoading(false);
      }
    } else {
      toast.warning("Please connect to wallet");
      setIsLoading(false);
    }
  };

  const getDappDetails = async () => {
    if (connected && signature?.length > 0) {
      updateWalletConnect(false);
      setIsLoading(true);
      try {
        const response = await handleDappDetails(address, signature, publicKey);
        if (response.status === 200 && response.data.success) {
          const dappData = JSON.parse(response.data.dapp);
          const wrappedDappArray = [dappData];
          if (response.data.dapp.length > 0) {
            updateAllowAllFunctions(wrappedDappArray[0].allow_all_functions);
            updateAllowAllScripts(wrappedDappArray[0].allow_scripts);
          } else {
            navigate("/register");
          }
        }
      } catch (error: any) {
        if (
          error?.response?.data?.success === false &&
          error?.response?.data?.message === "Paymaster not available"
        ) {
          navigate("/register");
        } else {
          console.error(error);
        }
      }
    }
  };

  useEffect(() => {
    if (connected) {
      if (network?.name?.toLowerCase() === "mainnet") {
        getWhitelistedFunctions();
        getWhitelistedModules();
      } else {
        navigate("/dashboard");
      }
    }
  }, [connected, network?.name]);

  const handleInputChange = (event: any) => {
    setInputValue(event.target.value);
  };

  const handleFetchClick = () => {
    if (inputValue.trim() !== "") {
      getAccountModules(inputValue);
    }
  };
  const handleTabChange = (tab: any) => {
    setSelectedTab(tab);
  };

  return (
    <div
      className={` !overflow-x-hidden  bg-[#0C0C0D]  min-h-screen h-full font-inter flex flex-row justify-start items-start w-screen xxl:!gap-[1rem] bxl:!gap-[1rem] xl:!gap-[1rem] sxl:!gap-[1rem] `}
    >
      <div className=" xxl:inline bxl:inline xl:inline sxl:inline lg:hidden md:hidden sm:hidden xd:hidden w-[5%]  ">
        <Sidebar />
      </div>
      <div className="w-full flex flex-row justify-center items-center ">
        <div className="flex flex-col  xxl:!w-[1600px] bxl:!w-[1600px] xl:!w-[90%] sxl:!w-[95.5%] lg:!w-[96%] md:!w-[100%] sm:!w-[100%] xd:!w-[100%]    ">
          <Header />
          <div>
            {!connected ? (
              <>{navigate("/dashboard")}</>
            ) : (
              <div className="mt-28 w-full">
                <div className="flex justify-center font-manrope">
                  <div className="w-[65%] md:w-full sm:w-full  xd:w-full h-full rounded-[1rem] border-2 border-[#ffffff1a] sm:border-none xd:border-none bg-[#17181A] sm:bg-transparent xd:bg-transparent flex flex-col justify-between">
                    <div>
                      <Tabs className="">
                        <TabList className=" border-b-2 border-[#ffffff1a] h-[3.5rem] text-[0.875rem] text-[#A5A5A6] font-[800] items-center w-full">
                          <div className=" flex justify-between">
                            <div className="flex">
                              <Tab
                                className={`cursor-pointer outline-none Tab_container p-[1.05rem] ${
                                  selectedTab === "deposit"
                                    ? "bg-[#252628] rounded-tl-[1rem] text-[#cfd0d0]"
                                    : ""
                                }`}
                                onClick={() => handleTabChange("deposit")}
                              >
                                <div>Functions</div>
                              </Tab>
                              <Tab
                                className={`cursor-pointer outline-none Tab_container p-[1.05rem]   ${
                                  selectedTab === "module"
                                    ? "bg-[#252628] text-[#cfd0d0]"
                                    : ""
                                }`}
                                onClick={() => handleTabChange("module")}
                              >
                                <div>Module</div>
                              </Tab>
                            </div>
                            <div className=" p-2">
                              {selectedTab === "module" && (
                                <div
                                  className="cursor-pointer border-[#2ED3B7] bg-[#2ED3B7] border-[1px] p-[0.25rem_0.8rem] rounded-[0.5rem] font-manrope text-[#17181A] font-[800] text-[0.875rem]"
                                  onClick={handleModulePopup}
                                >
                                  Add Module
                                </div>
                              )}
                              {selectedTab === "deposit" && (
                                <div
                                  className="cursor-pointer border-[#2ED3B7] bg-[#2ED3B7] border-[1px] p-[0.25rem_0.8rem] rounded-[0.5rem] font-manrope text-[#17181A] font-[800] text-[0.875rem]"
                                  onClick={handleFunctionPopup}
                                >
                                  Add Function
                                </div>
                              )}
                            </div>
                          </div>
                        </TabList>
                        <TabPanel>
                          <div className="w-full flex flex-col justify-center items-center">
                            <div className="border-b-2 border-[#ffffff1a] w-full p-3">
                              <div className="flex flex-col justify-start items-start w-full px-[1rem] py-[1rem] rounded-[1rem] bg-[#111213] gap-[1rem] border-[#ffffff1a] border-2">
                                <div className="flex flex-row justify-between items-center w-full">
                                  <div className="text-[rgba(255,255,255,0.80)] flex font-manrope font-[800] text-[0.875rem]">
                                    Allow All Functions
                                    <Tooltip title="By default, the feature is active, allowing all functions. However, upon clicking, it will disable all functions allowances.">
                                      <img
                                        src={Information}
                                        alt="Information"
                                        className="mx-2"
                                        onMouseEnter={() => setIsHovered(true)}
                                        onMouseLeave={() => setIsHovered(false)}
                                      />
                                    </Tooltip>
                                  </div>
                                  <div className="cursor-pointer p-[0.25rem_0.8rem] rounded-[0.5rem] font-manrope text-[#2ED3B7] font-[800] text-[0.875rem] gap-[0.5rem] flex flex-row items-center">
                                    <Switch
                                      checked={allowAllFunctions}
                                      onChange={() => {
                                        if (allowAllFunctions) {
                                          modifyAllowAllFunctionsStateInActive();
                                        } else {
                                          modifyAllowAllFunctionsStateActive();
                                        }
                                      }}
                                    />
                                    <span
                                      style={{
                                        color: allowAllFunctions
                                          ? "#2ED3B7"
                                          : "red",
                                      }}
                                    >
                                      {allowAllFunctions
                                        ? "Active"
                                        : "Inactive"}
                                    </span>
                                  </div>
                                </div>
                                <div className="flex flex-row justify-between items-center w-full">
                                  <div className="text-[rgba(255,255,255,0.80)] flex font-manrope font-[800] text-[0.875rem]">
                                    Allow All Scripts
                                    <Tooltip title="By default, the feature is active, allowing all scripts. However, upon clicking, it will disable all script allowances.">
                                      <img
                                        src={Information}
                                        alt="Information"
                                        className="mx-2"
                                        onMouseEnter={() => setIsHovered(true)}
                                        onMouseLeave={() => setIsHovered(false)}
                                      />
                                    </Tooltip>
                                  </div>
                                  <div className="cursor-pointer p-[0.25rem_0.8rem] rounded-[0.5rem] font-manrope font-[800] text-[0.875rem] gap-[0.5rem] flex flex-row items-center">
                                    <Switch
                                      checked={allowAllScripts}
                                      onChange={() => {
                                        if (allowAllScripts) {
                                          modifyAllowAllScriptsStateInActive();
                                        } else {
                                          modifyAllowAllScriptsStateActive();
                                        }
                                      }}
                                    />
                                    <span
                                      style={{
                                        color: allowAllScripts
                                          ? "#2ED3B7"
                                          : "red",
                                      }}
                                    >
                                      {allowAllScripts ? "Active" : "Inactive"}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="border-b-2 border-[#ffffff1a] h-[3.5rem] text-[0.875rem] text-[#A5A5A6] font-[800] flex flex-row justify-between items-center w-full p-3">
                              <div>Whitelisted Functions</div>
                            </div>
                            {isLoading && (
                              <div className="fixed inset-0  !z-[2] h-full  ">
                                <div className="flex justify-center items-center m-8 align-middle h-full w-full">
                                  <Lottie
                                    loop
                                    animationData={Kanaloader}
                                    play
                                    style={{ width: 90, height: 90 }}
                                  />
                                </div>
                              </div>
                            )}
                            {Object.values(whitelistedFunctions).length > 0 ? (
                              <div className="p-3 w-full flex flex-col gap-[1rem] h-[400px] overflow-y-scroll">
                                {Object.values(whitelistedFunctions)?.map(
                                  (func: any, index: any) => (
                                    <div
                                      key={index}
                                      className="flex flex-col justify-start items-start w-full rounded-[1rem] bg-[#111213] gap-[1rem] border-[#ffffff1a] border-2 px-[1.5rem] py-[1rem]"
                                    >
                                      <div
                                        onClick={handleOpenFunction}
                                        className="cursor-pointer flex flex-row justify-between items-center w-full"
                                      >
                                        <div className="text-[rgba(255,255,255,0.80)] font-manrope font-[400] text-[0.875rem]">
                                          {func.module_address}
                                        </div>
                                        <img
                                          src={ChevronDown}
                                          className={`cursor-pointer ${
                                            isOpenFunction
                                              ? ""
                                              : "rotate-[180deg] "
                                          }`}
                                          alt=""
                                        />
                                      </div>
                                      {isOpenFunction && (
                                        <div className="border-t-[1px] border-[rgba(255,255,255,0.10)] w-full pt-[1rem] flex flex-col gap-[1rem]">
                                          <div
                                            onClick={() =>
                                              handleWhitelistOpenSubFunction(
                                                index
                                              )
                                            }
                                            className="cursor-pointer text-[1rem] font-[800] flex flex-row justify-start items-center gap-[1rem] font-manrope text-[rgba(255,255,255,0.80)]"
                                          >
                                            <img
                                              src={DropDown}
                                              alt=""
                                              className={`cursor-pointer ${
                                                isOpenSubFunction
                                                  ? ""
                                                  : "rotate-[270deg] "
                                              }`}
                                            />
                                            {func?.module_name}
                                          </div>
                                          {isOpenSubFunction && (
                                            <div className="flex flex-col justify-start items-start gap-[1rem] ml-[40px]">
                                              <div className="text-[#A5A5A6] font-[400] font-manrope text-[1rem] flex flex-row justify-between items-center w-full">
                                                {func?.function_name}
                                                <div className="flex flex-row justify-start items-center font-[400] text-[0.875rem] font-manrope gap-[0.5rem]">
                                                  <Switch
                                                    checked={func?.is_active}
                                                    onChange={() => {
                                                      const newStatus =
                                                        !func?.is_active;
                                                      modifyFunctionState(
                                                        func?.module_name,
                                                        func?.function_name,
                                                        func?.module_address,
                                                        newStatus.toString()
                                                      );
                                                    }}
                                                  />

                                                  {func?.is_active ? (
                                                    <span className="text-[#2ED3B7] font-[800] text-[0.875rem]">
                                                      Active
                                                    </span>
                                                  ) : (
                                                    <span className="text-red-500 font-[800] text-[0.875rem]">
                                                      InActive
                                                    </span>
                                                  )}
                                                </div>
                                              </div>
                                            </div>
                                          )}
                                        </div>
                                      )}
                                    </div>
                                  )
                                )}
                              </div>
                            ) : (
                              <div></div>
                            )}
                          </div>
                        </TabPanel>
                        <TabPanel>
                          <div className="w-full flex flex-col justify-center items-center">
                            <div className="border-b-2 border-[#ffffff1a] h-[3.5rem] text-[0.875rem] text-[#A5A5A6] font-[800] flex flex-row justify-between items-center w-full p-3">
                              <div>Whitelisted Module</div>
                            </div>
                            {isLoading && (
                              <div className="fixed inset-0  !z-[2] h-full  ">
                                <div className="flex justify-center items-center m-8 align-middle h-full w-full">
                                  <Lottie
                                    loop
                                    animationData={Kanaloader}
                                    play
                                    style={{ width: 90, height: 90 }}
                                  />
                                </div>
                              </div>
                            )}
                            {Object.values(whitelistedModules).length > 0 ? (
                              <div className="p-3 w-full flex flex-col gap-[1rem] h-[400px] overflow-y-scroll">
                                {Object.values(whitelistedModules)?.map(
                                  (func: any, index: any) => (
                                    <div
                                      key={index}
                                      className="flex flex-row justify-between items-center w-full rounded-[1rem] bg-[#111213] gap-[1rem] border-[#ffffff1a] border-2 px-[1.5rem] py-[1rem]"
                                    >
                                      <div
                                        onClick={handleOpenFunction}
                                        className="cursor-pointer flex flex-row justify-between items-center w-full"
                                      >
                                        <div className="text-[rgba(255,255,255,0.80)] font-manrope font-[400] text-[0.875rem]">
                                          {func.module_address}
                                        </div>
                                        {/* <img
                                    src={ChevronDown}
                                    className={`cursor-pointer ${
                                      isOpenFunction ? "" : "rotate-[180deg] "
                                    }`}
                                    alt=""
                                  /> */}
                                      </div>
                                      <div>
                                        <div className="flex flex-row justify-start items-center font-[400] text-[0.875rem] font-manrope gap-[0.5rem]">
                                          <Switch
                                            checked={func?.is_active}
                                            onChange={() => {
                                              const newStatus =
                                                !func?.is_active;
                                              modifyModulesState(
                                                func?.module_address,
                                                newStatus.toString()
                                              );
                                            }}
                                          />

                                          {func?.is_active ? (
                                            <span className="text-[#2ED3B7] font-[800] text-[0.875rem]">
                                              Active
                                            </span>
                                          ) : (
                                            <span className="text-red-500 font-[800] text-[0.875rem]">
                                              InActive
                                            </span>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  )
                                )}
                              </div>
                            ) : (
                              <div></div>
                            )}
                          </div>
                        </TabPanel>
                      </Tabs>
                    </div>
                  </div>
                </div>
                {isAddFunctionPopup && (
                  <div className="fixed !z-[4] inset-0 h-full w-full bg-[rgba(0,0,0,0.20)] backdrop-blur-[2rem] flex flex-row justify-center 2xl:items-center bxl:items-center xl:items-center sxl:items-center lg:items-center md:items-center sm:items-center xd:items-center">
                    <ClickAwayListener onClickAway={handleClickAway}>
                      <div
                        className={`2xl:w-[35rem] bxl:w-[35rem] xl:w-[35rem] sxl:w-[35rem] lg:w-[35rem] md:w-[35rem] sm:w-[21rem] max-sm:w-[21rem] h-[36.5rem] ${"bg-gradient-to-r from-[#f5fffa0f] to-[#F5F7FA00] border-[#1e1f22] backdrop-blur-[54.36563491821289px] bg-transparent"} border-2 rounded-[1rem] flex flex-col gap-[1rem]`}
                      >
                        <div className="flex justify-between p-[3%_5%] bg-[rgba(255,255,255,0.06)] rounded-t-[1rem]">
                          <div
                            className={`text-${"white"} font-manrope font-[800] text-[1.25rem]`}
                          >
                            Whitelist Function
                          </div>
                          <img
                            onClick={handleClickAway}
                            src={close}
                            alt="close"
                            className="cursor-pointer"
                          />
                        </div>
                        <div className="flex justify-center px-[1rem] w-full">
                          <div className=" flex flex-row justify-between items-center w-full px-[1.5rem] py-[1rem] rounded-[1rem] bg-[#111213] gap-[1rem] border-[#ffffff1a] border-2  overflow-x-hidden ">
                            <input
                              className="text-[16px] font-[600] text-[white] flex flex-row justify-start items-center w-full bg-transparent outline-none"
                              placeholder="Enter address"
                              value={inputValue}
                              onChange={handleInputChange}
                            />
                            <div
                              className="cursor-pointer border-[#2ED3B7] bg-[#2ED3B7] border-[1px] p-[0.25rem_0.8rem] rounded-[0.5rem] font-manrope  text-[#17181A] font-[800] text-[0.875rem]"
                              onClick={() => handleFetchClick()}
                            >
                              Fetch
                            </div>
                          </div>
                        </div>
                        {isLoading && (
                          <div className="fixed inset-0  !z-[2] h-full  ">
                            <div className="flex flex-row justify-center items-center h-full w-full">
                              <Lottie
                                loop
                                animationData={Kanaloader}
                                play
                                style={{ width: 100, height: 100 }}
                              />
                            </div>
                          </div>
                        )}
                        <div className="flex justify-center px-[1rem] w-full">
                          <div className="flex flex-col h-[20rem] justify-start items-start w-full px-[1.5rem] py-[1rem] rounded-[1rem] bg-[#111213] gap-[1rem] border-[#ffffff1a] border-2  overflow-x-hidden ">
                            {responseData &&
                              responseData.length > 0 &&
                              responseData
                                .filter((response: any) =>
                                  response?.abi?.exposed_functions?.some(
                                    (func: any) => func?.is_entry
                                  )
                                )
                                .map((response: any, index: any) => (
                                  <div
                                    key={index}
                                    className="text-[1rem] cursor-pointer font-[800] flex flex-col justify-start gap-[1rem] font-manrope text-[rgba(255,255,255,0.80)] w-full"
                                  >
                                    <div
                                      onClick={() =>
                                        handleOpenSubFunction(index)
                                      }
                                      className="flex items-center gap-[1rem]"
                                    >
                                      <img
                                        src={DropDown}
                                        alt=""
                                        className={`cursor-pointer ${
                                          selectedAbiIndex === index
                                            ? ""
                                            : "rotate-[270deg] "
                                        }`}
                                      />
                                      {response?.abi?.name}
                                    </div>
                                    {selectedAbiIndex === index &&
                                      response?.abi?.exposed_functions
                                        .filter((func: any) => func?.is_entry)
                                        .map((func: any, funcIndex: any) => (
                                          <div
                                            key={funcIndex}
                                            className="text-[#A5A5A6] font-[400] font-manrope text-[1rem] flex justify-between items-center w-full"
                                          >
                                            {func?.name}
                                            <div
                                              className="cursor-pointer border-[#2ED3B7] border-[1px] p-[0.25rem_0.8rem] ml-2 rounded-[0.5rem] font-manrope text-[#2ED3B7] font-[800] text-[0.875rem]"
                                              onClick={() =>
                                                addFunctionWhitelist(
                                                  response?.abi?.name,
                                                  func?.name,
                                                  inputValue
                                                )
                                              }
                                            >
                                              Add
                                            </div>
                                          </div>
                                        ))}
                                  </div>
                                ))}
                          </div>
                        </div>
                        <div
                          className="px-[1rem] pb-[1rem]"
                          onClick={handleClickAway}
                        >
                          <button className="buy-button w-full ">
                            <div className="buy-button-inner">
                              <span className=" text-[#2ED3B7] font-[800]">
                                Close
                              </span>
                            </div>
                          </button>
                        </div>
                      </div>
                    </ClickAwayListener>
                  </div>
                )}
                {isaAddModulePopup && (
                  <div className="fixed !z-[4] inset-0 h-full w-full bg-[rgba(0,0,0,0.20)] backdrop-blur-[2rem] flex flex-row justify-center 2xl:items-center bxl:items-center xl:items-center sxl:items-center lg:items-center md:items-center sm:items-center xd:items-center">
                    <ClickAwayListener onClickAway={handleClickAway}>
                      <div
                        className={`2xl:w-[35rem] bxl:w-[35rem] xl:w-[35rem] sxl:w-[35rem] lg:w-[35rem] md:w-[35rem] sm:w-[21rem] max-sm:w-[21rem] ${"bg-gradient-to-r from-[#f5fffa0f] to-[#F5F7FA00] border-[#1e1f22] backdrop-blur-[54.36563491821289px] bg-transparent"} border-2 rounded-[1rem] flex flex-col gap-[1rem]`}
                      >
                        <div className="flex justify-between p-[3%_5%] bg-[rgba(255,255,255,0.06)] rounded-t-[1rem]">
                          <div
                            className={`text-${"white"} font-manrope font-[800] text-[1.25rem]`}
                          >
                            Whitelist Module
                          </div>
                          <img
                            onClick={handleClickAway}
                            src={close}
                            alt="close"
                            className="cursor-pointer"
                          />
                        </div>
                        <div className="flex justify-center px-[1rem] w-full">
                          <div className=" flex flex-row justify-between items-center w-full px-[1.5rem] py-[1rem] rounded-[1rem] bg-[#111213] gap-[1rem] border-[#ffffff1a] border-2  overflow-x-hidden ">
                            <input
                              className="text-[16px] font-[600] text-[white] flex flex-row justify-start items-center w-full bg-transparent outline-none"
                              placeholder="Enter address"
                              value={inputValue}
                              onChange={handleInputChange}
                            />
                            <div
                              className="cursor-pointer border-[#2ED3B7] bg-[#2ED3B7] border-[1px] p-[0.25rem_0.8rem] rounded-[0.5rem] font-manrope  text-[#17181A] font-[800] text-[0.875rem]"
                              onClick={() => addmoduleWhitelist(inputValue)}
                            >
                              add
                            </div>
                          </div>
                        </div>
                        {isLoading && (
                          <div className="fixed inset-0  !z-[2] h-full  ">
                            <div className="flex flex-row justify-center items-center h-full w-full">
                              <Lottie
                                loop
                                animationData={Kanaloader}
                                play
                                style={{ width: 100, height: 100 }}
                              />
                            </div>
                          </div>
                        )}
                        <div className="flex justify-center px-[1rem] w-full">
                          {/* <div className="flex flex-col h-[20rem] justify-start items-start w-full px-[1.5rem] py-[1rem] rounded-[1rem] bg-[#111213] gap-[1rem] border-[#ffffff1a] border-2  overflow-x-hidden ">
                      {responseData &&
                        responseData.length > 0 &&
                        responseData
                          .filter((response: any) =>
                            response?.abi?.exposed_functions?.some(
                              (func: any) => func?.is_entry
                            )
                          )
                          .map((response: any, index: any) => (
                            <div
                              key={index}
                              className="text-[1rem] cursor-pointer font-[800] flex flex-col justify-start gap-[1rem] font-manrope text-[rgba(255,255,255,0.80)] w-full"
                            >
                              <div
                                onClick={() => handleOpenSubFunction(index)}
                                className="flex items-center gap-[1rem]"
                              >
                                <img
                                  src={DropDown}
                                  alt=""
                                  className={`cursor-pointer ${
                                    selectedAbiIndex === index
                                      ? ""
                                      : "rotate-[270deg] "
                                  }`}
                                />
                                {response?.abi?.name}
                              </div>
                              {selectedAbiIndex === index &&
                                response?.abi?.exposed_functions
                                  .filter((func: any) => func?.is_entry)
                                  .map((func: any, funcIndex: any) => (
                                    <div
                                      key={funcIndex}
                                      className="text-[#A5A5A6] font-[400] font-manrope text-[1rem] flex justify-between items-center w-full"
                                    >
                                      {func?.name}
                                      <div
                                        className="cursor-pointer border-[#2ED3B7] border-[1px] p-[0.25rem_0.8rem] ml-2 rounded-[0.5rem] font-manrope text-[#2ED3B7] font-[800] text-[0.875rem]"
                                        onClick={() =>
                                          addmodule(
                                            response?.abi?.name,
                                            func?.name,
                                            inputValue
                                          )
                                        }
                                      >
                                        Add
                                      </div>
                                    </div>
                                  ))}
                            </div>
                          ))}
                    </div> */}
                        </div>
                        <div
                          className="px-[1rem] pb-[1rem]"
                          onClick={handleClickAway}
                        >
                          <button className="buy-button w-full ">
                            <div className="buy-button-inner">
                              <span className=" text-[#2ED3B7] font-[800]">
                                Close
                              </span>
                            </div>
                          </button>
                        </div>
                      </div>
                    </ClickAwayListener>
                  </div>
                )}
              </div>
            )}
            {iswalletConnect && <ConnectWallet />}
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default FunctionsWhitelist;
