/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import DonutChart from "./DonutChart";
import TinyBarChart from "./TinyBarChart";
import Admin from "../assets/icons/users-admin.svg";
import {
  fetchUSDPriceOfAptos,
  handleTotalTxnsGasUsedData,
  handleParticularUserData,
  handleTopFunctionData,
  handleDappInformation,
} from "utils/helpers";
import { useWallet } from "@aptos-labs/wallet-adapter-react";
import { useNavigate } from "react-router-dom";
import Gasused from "../assets/icons/petrol-pump.png";
import { useStore } from "store";
import ArrownDown from "../assets/icons/dropdown_arrow.svg";
import {
  AssignSignerAddress,
  chingari,
  Eragon,
  Floc,
  Kanaspot,
  Wapal,
} from "utils/constants";
import Kanaloader from "../assets/kanaloader.json";
import Lottie from "react-lottie-player";
import Sidebar from "./Sidebar";
import Header from "./Header";
import Footer from "./Footer";
const AdminDashboard = () => {
  const [isOpenSubFunction, setIsOpenSubFunction] = useState(false);
  const [isOpenFunction, setIsOpenFunction] = useState(false);
  const [totalGas, setTotalGas] = useState<any>();
  const [chingariOnchain, setChingariOnchain] = useState<any>();
  const [kanaSpotOnchain, setKanaSpotOnchain] = useState<any>();
  const [testingOnchain, setTestingOnchain] = useState<any>();
  const [wapalOnchain, setWapalOnchain] = useState<any>();
  const [flocOnchain, setFlocOnchain] = useState<any>();
  const [eragonOnchain, setEragonOnchain] = useState<any>();
  const [gasUsedInUSD, setGasUsedInUSD] = useState<any>();
  const [overallTopFunctions, setOverallTopFunctions] = useState<any>();
  const [topFunctionCount, setTopFunctionCount] = useState<any>();
  const [averageGasUsed, setAverageGasUsed] = useState<any>();
  const [dropdown, setDropdown] = useState(false);
  const [data, setData] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(true);
  const { connected } = useWallet();
  const {
    updateParticularDappData,
    updateParticularFeepayeraddress,
    sponseredUsers,
    updateSponseredUsers,
    setTotalUsers,
    totalUsers,
    setParticularFeepayerAddressTxns,
  } = useStore();
  const navigate = useNavigate();
  let loading: any;

  const [openDropdown, setOpenDropdown] = useState(null);

  const handleDropmenu = (index: any) => {
    setOpenDropdown(index === openDropdown ? null : index);
  };

  const handleOpenFunction = () => {
    setIsOpenFunction(!isOpenFunction);
  };
  const handleWhitelistOpenSubFunction = (index: any) => {
    setIsOpenSubFunction(!isOpenSubFunction);
  };

  // const handleDropmenu = () => {
  //   setDropdown(!dropdown);
  // };

  const handleClick = () => {
    navigate("/all-DApps");
  };
  const handleOnchainDetails = async (address: any) => {
    const topFunctionforParticularFeepayer = await handleDappInformation(
      address
    );
    if (address === chingari) {
      setParticularFeepayerAddressTxns(AssignSignerAddress.Chingari);
    } else if (address === Kanaspot) {
      setParticularFeepayerAddressTxns(AssignSignerAddress.KanaSpot);
    } else if (address === Wapal) {
      setParticularFeepayerAddressTxns(AssignSignerAddress.Wapal);
    } else if (address === Eragon) {
      setParticularFeepayerAddressTxns(AssignSignerAddress.Eragon);
    } else {
      setParticularFeepayerAddressTxns(AssignSignerAddress.Floc);
    }
    updateParticularDappData(topFunctionforParticularFeepayer);
    updateParticularFeepayeraddress(address);
    navigate("/dapp-Information");
  };

  const TotalTxnGasUsedData = async () => {
    if (connected) {
      const response = await handleTotalTxnsGasUsedData();
      if (response) {
        const totalGasUsedinUSD = response.overall_gas_cost;
        const totalTranscationGasusedInUSD = await fetchUSDPriceOfAptos(
          totalGasUsedinUSD
        );
        const onchain = await handleParticularUserData();
        const topFunctions = await handleTopFunctionData();
        setChingariOnchain(onchain[0].total_transactions_till_now);
        setKanaSpotOnchain(onchain[2].total_transactions_till_now);
        setWapalOnchain(onchain[1].total_transactions_till_now);
        setEragonOnchain(onchain[4].total_transactions_till_now);
        setFlocOnchain(onchain[5].total_transactions_till_now);
        setAverageGasUsed(response.overall_average_cost);
        setTotalGas(response.overall_gas_cost);
        setTotalUsers(response.overall_users_till_now);
        updateSponseredUsers(response.overall_transactions);
        setGasUsedInUSD(totalTranscationGasusedInUSD);
        setOverallTopFunctions(topFunctions.overallFunctionCount);
        setData(topFunctions.data);
        setIsLoading(false);
      } else {
        // Handle the scenario where response is undefined or empty
        console.error("Failed to fetch total gas data.");
      }
    }
  };

  useEffect(() => {
    if (connected) {
      TotalTxnGasUsedData();
      setIsLoading(true);
    }
  }, [connected]);

  return (
    <div
      className={` !overflow-x-hidden  bg-[#0C0C0D]  min-h-screen h-full font-inter flex flex-row justify-start items-start w-screen xxl:!gap-[1rem] bxl:!gap-[1rem] xl:!gap-[1rem] sxl:!gap-[1rem] `}
    >
      <div className=" xxl:inline bxl:inline xl:inline sxl:inline lg:hidden md:hidden sm:hidden xd:hidden w-[5%]  ">
        <Sidebar />
      </div>
      <div className="w-full flex flex-row justify-center items-center ">
        <div className="flex flex-col  xxl:!w-[1600px] bxl:!w-[1600px] xl:!w-[90%] sxl:!w-[95.5%] lg:!w-[96%] md:!w-[100%] sm:!w-[100%] xd:!w-[100%]    ">
          <Header />
          <div>
            {!connected ? (
              <>{navigate("/dashboard")}</>
            ) : (
              <>
                {isLoading && (
                  <div
                    className={`fixed inset-0 !z-[2]  backdrop-blur-2xl  font-inter  items-center overflow-y-auto  h-[100%] w-[100%] flex flex-col justify-start  bg-[black]   `}
                  >
                    <div className="flex justify-center align-middle pt-60 !bg-transparent">
                      <Lottie
                        className="!bg-transparent"
                        loop
                        animationData={Kanaloader}
                        play
                        style={{ width: 150, height: 150 }}
                      />
                    </div>
                    <div
                      className={`!bg-transparent text-center text-[0.875rem]  align-middle 2xl:pt-28 xl:pt-28 lg:pt-28 md:pt-10 sm:pt-4 max-sm:pt-4 text-[#FFFFFF] `}
                    >
                      This may take few seconds, don’t close the window
                    </div>
                  </div>
                )}
                <div className=" font-manrope">
                  <div className=" w-full xxl:bg-[#17181A] xl:bg-[#17181A] bxl:bg-[#17181A]  sxl:bg-[#17181A] lg:bg-none md:bg-none sm:bg-none xd:bg-none xxl:border-2 xl:border-2 bxl:border-2 sxl:border-2 lg:border-none md:border-none  border-[#ffffff1a] font-manrope rounded-[1rem] h-[26.5rem] xxl:mt-28 xl:mt-28 bxl:mt-28 sxl:mt-28 lg:mt-[6rem] md:mt-[6rem] sm:mt-[6rem] xd:mt-[6rem] flex xxl:flex-row xl:flex-row bxl:flex-row sxl:flex-row lg:flex-col md:flex-col xd:flex-col sm:flex-col justify-around p-3 gap-[1rem]">
                    <div className=" flex flex-col gap-[1rem]">
                      <div className="xxl:bg-[#0c0c0d] xl:bg-[#0c0c0d] bxl:bg-[#0c0c0d] sxl:bg-[#0c0c0d] lg:bg-[#17181A] md:bg-[#17181A] sm:bg-[#17181A] xd:bg-[#17181A]  xxl:w-[26.167rem] xl:w-[26.167rem] bxl:w-[26.167rem] lg:w-full md:w-full sm:w-full xd:w-full rounded-[1rem] flex gap-[1rem] p-[4%_6%]">
                        <div className=" w-[3.5rem] h-[3.5rem] rounded-[0.5rem] border-2 border-[#242425] flex justify-center items-center align-middle">
                          <img src={Admin} alt="admin" />
                        </div>
                        <div>
                          <div className="text-[#A5A5A6] text-[0.75rem]">
                            Total Number of users sponsored
                          </div>
                          <div className=" text-[2.25rem] font-[800] text-[#FFFFFFCC]">
                            {totalUsers && totalUsers.toLocaleString()}

                            {isLoading && (
                              <Lottie
                                className="!bg-transparent"
                                loop
                                animationData={Kanaloader}
                                play
                                style={{ width: 30, height: 30 }}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                      <div className=" xxl:bg-[#0C0C0D] xl:bg-[#0C0C0D] bxl:bg-[#0C0C0D] sxl:bg-[#0C0C0D] lg:bg-[#17181A] md:bg-[#17181A] sm:bg-[#17181A] xd:bg-[#17181A] h-[17.625rem] xxl:w-[26.167rem] xl:w-[26.167rem] bxl:w-[26.167rem] lg:w-full md:w-full sm:w-full xd:w-full rounded-[1rem] flex justify-center items-center align-middle">
                        <DonutChart />
                      </div>
                    </div>
                    <div className=" xxl:bg-[#0C0C0D] xl:bg-[#0C0C0D] bxl:bg-[#0C0C0D] sxl:bg-[#0C0C0D] lg:bg-[#17181A] md:bg-[#17181A] sm:bg-[#17181A] xd:bg-[#17181A] rounded-[1rem] flex flex-col justify-center items-center align-middle p-[0%_6%]">
                      <div className="text-[1.5rem] text-[#FFFFFFCC] font-[800]">
                        Last 7 Days Transactions
                      </div>
                      <div className=" flex justify-center items-center align-middle mt-5">
                        <TinyBarChart />
                      </div>
                    </div>
                    <div className="flex flex-col gap-[1rem]">
                      <div className="xxl:bg-[#0C0C0D] xl:bg-[#0C0C0D] bxl:bg-[#0C0C0D] sxl:bg-[#0C0C0D] lg:bg-[#17181A] md:bg-[#17181A] sm:bg-[#17181A] xd:bg-[#17181A] rounded-[1rem] flex gap-[1rem] p-[3%_6%]">
                        {/* <div className=" rounded-[0.5rem] border-2 border-[#242425] flex justify-center items-center align-middle">
                          <img src={Hashtx} alt="admin" />
                        </div> */}
                        <div>
                          <div className="text-[#A5A5A6] text-[0.75rem] flex">
                            <span>Number of transactions</span>
                          </div>
                          <div className="text-[2.25rem] font-[800] text-[#FFFFFFCC]">
                            {sponseredUsers && sponseredUsers.toLocaleString()}
                          </div>
                        </div>
                      </div>
                      <div className="xxl:bg-[#0c0c0d] xl:bg-[#0c0c0d] bxl:bg-[#0c0c0d] sxl:bg-[#0c0c0d] lg:bg-[#17181A] md:bg-[#17181A] sm:bg-[#17181A] xd:bg-[#17181A] rounded-[1rem] flex gap-[1rem] p-[1rem]">
                        {/* <div className=" w-[3.5rem] h-[3.5rem] rounded-[0.5rem] border-2 border-[#242425] flex justify-center items-center align-middle">
                          <img src={Average} alt="admin" />
                        </div> */}
                        <div>
                          <div className="text-[#A5A5A6] text-[0.75rem] flex">
                            <span>Average Gas Used </span>
                            <img
                              src={Gasused}
                              alt="/"
                              className=" w-4 h-4 ml-2 mt-[-0.1rem]"
                            />
                          </div>
                          <div className=" text-[2.25rem] font-[800] text-[#FFFFFFCC]">
                            {averageGasUsed &&
                              Number(averageGasUsed).toFixed(7)}
                            <span className=" text-[1rem] pl-3">APT</span>
                          </div>
                        </div>
                      </div>
                      <div className=" xxl:bg-[#0C0C0D] xl:bg-[#0C0C0D] bxl:bg-[#0C0C0D] sxl:bg-[#0C0C0D] lg:bg-[#17181A] md:bg-[#17181A] sm:bg-[#17181A] xd:bg-[#17181A] rounded-[1rem] p-[4%_6%] flex gap-[1rem]">
                        {/* <div className=" w-[3.5rem] h-[3.5rem] rounded-[0.5rem] border-2 border-[#242425] flex justify-center items-center align-middle">
                          <img src={Gastx} alt="admin" />
                        </div> */}
                        <div>
                          <div>
                            <div className="text-[#A5A5A6] text-[0.75rem] flex ">
                              <span>Total Gas Used</span>
                              <img
                                src={Gasused}
                                alt="/"
                                className=" w-4 h-4 ml-2 mt-[-0.1rem]"
                              />
                            </div>
                            <div className=" text-[2.25rem] font-[800] text-[#FFFFFFCC]">
                              {totalGas && Number(totalGas).toFixed(2)}
                              <span className=" text-[1rem] pl-3">APT</span>
                            </div>
                          </div>
                          <div>
                            <div className="text-[#A5A5A6] text-[0.75rem] flex">
                              <span> Total Gas Used in USD</span>
                              <img
                                src={Gasused}
                                alt="/"
                                className=" w-4 h-4 ml-2 mt-[-0.1rem]"
                              />
                            </div>
                            <div className="  text-[#FFFFFFCC] text-[1.25rem]">
                              $
                              <span className="pl-1 text-[2.25rem] font-[800]">
                                {gasUsedInUSD}
                              </span>
                              <span className=" text-[1rem] pl-3">USD</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className=" w-full xxl:mt-3 xl:mt-3 sxl:mt-3 bxl:mt-3 lg:mt-[74rem] md:mt-[48rem] sm:mt-[47rem] xd:mt-[47rem] flex xxl:flex-row xl:flex-row sxl:flex-row bxl:flex-row lg:flex-col md:flex-col sm:flex-col xd:flex-col ">
                    <div className=" xxl:w-1/2 xl:w-1/2 sxl:w-1/2 bxl:w-1/2 lg:w-full md:w-full sm:w-full xd:w-full xxl:bg-[#17181A] xl:bg-[#17181A] sxl:bg-[#17181A] bxl:bg-[#17181A] lg:bg-[#17181A] md:bg-transparent sm:bg-transparent xd:bg-transparent border-2 md:border-none sm:border-none xd:bordern border-[#FFFFFF1A] rounded-[1rem] h-[37.125rem] overflow-auto no-scrollbar">
                      <div className="h-[3.5rem] border-b-2 border-[#ffffff1a] flex justify-between p-3">
                        <div className="text-[#FFFFFF] text-[0.875rem] font-[800]">
                          Top DApps
                        </div>
                        <div className=" flex">
                          {/* <div className=" flex text-[0.75rem] text-[#2ED3B7] font-[800]">
                      <img src={Filter} alt="Filter" className=" w-5 h-5" />
                      <span className=" p-1">This week</span>
                    </div>
                    <div className=" flex text-[0.75rem] text-[#2ED3B7] font-[800]">
                      <span className=" p-1">Transaction Count</span>
                      <img src={DownGreen} alt="DownGreen" />
                    </div> */}
                          <button
                            className=" flex text-[0.75rem] text-[#17181A] bg-[#2ED3B7] rounded-[0.5rem] gap-[0.5rem] font-[800] p-3 fle items-center justify-center"
                            onClick={handleClick}
                          >
                            <span className=" p-1">All DApp</span>
                          </button>
                        </div>
                      </div>
                      <div className=" h-[4.625rem] bg-[#111213] border-b-2 border-[#ffffff1a] flex justify-between p-4">
                        <div className=" flex flex-col">
                          <div className="text-[1rem] text-[#FFFFFFCC] font-[800]">
                            Total Number of Transactions
                          </div>
                        </div>
                        <div className="text-[1rem] text-[#FFFFFFCC] font-[800]">
                          {sponseredUsers && sponseredUsers.toLocaleString()}
                        </div>
                      </div>
                      <div className="pt-3 flex flex-col justify-center align-middle items-center  ">
                        <div
                          className="bg-[#111213] hover:bg-[#252628] rounded-[1rem] border-2 border-[#ffffff1a]  xxl:w-[38.75rem] xl:w-[38.75rem] sxl:w-[38.75rem] bxl:w-[38.75rem] lg:w-[38.75rem] sm:w-full md:w-full xd:w-full h-[5rem] flex justify-between p-4 cursor-pointer"
                          onClick={() => handleOnchainDetails(chingari)}
                        >
                          <div className=" flex flex-col">
                            <div className=" truncate text-[0.875rem] text-[#FFFFFF] font-[800]">
                              CHINGARI
                            </div>
                          </div>
                          <div className="text-[#FFFFFFCC] font-[800] flex">
                            {chingariOnchain &&
                              chingariOnchain.toLocaleString()}
                            <span className=" font-[400] pl-1 text-[#A5A5A6]">
                              txns
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="pt-3 flex flex-col justify-center align-middle items-center  ">
                        <div
                          className="bg-[#111213] hover:bg-[#252628] rounded-[1rem] border-2 border-[#ffffff1a] xxl:w-[38.75rem] xl:w-[38.75rem] sxl:w-[38.75rem] bxl:w-[38.75rem] lg:w-[38.75rem] sm:w-full md:w-full xd:w-full h-[5rem] flex justify-between p-4 cursor-pointer"
                          onClick={() => handleOnchainDetails(Kanaspot)}
                        >
                          <div className=" flex flex-col">
                            <div className=" truncate text-[0.875rem] text-[#FFFFFF] font-[800]">
                              KANASPOT
                            </div>
                          </div>
                          <div className="text-[#FFFFFFCC] font-[800] flex">
                            {kanaSpotOnchain &&
                              kanaSpotOnchain.toLocaleString()}
                            <span className=" font-[400] pl-1 text-[#A5A5A6]">
                              txns
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="pt-3 flex flex-col justify-center align-middle items-center  ">
                        <div
                          className="bg-[#111213] hover:bg-[#252628] rounded-[1rem] border-2 border-[#ffffff1a] xxl:w-[38.75rem] xl:w-[38.75rem] sxl:w-[38.75rem] bxl:w-[38.75rem] lg:w-[38.75rem] sm:w-full md:w-full xd:w-full h-[5rem] flex justify-between p-4 cursor-pointer"
                          onClick={() => handleOnchainDetails(Wapal)}
                        >
                          <div className=" flex flex-col">
                            <div className=" truncate text-[0.875rem] text-[#FFFFFF] font-[800]">
                              WAPAL
                            </div>
                          </div>
                          <div className="text-[#FFFFFFCC] font-[800] flex">
                            {wapalOnchain && wapalOnchain.toLocaleString()}
                            <span className=" font-[400] pl-1 text-[#A5A5A6]">
                              txns
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="pt-3 flex flex-col justify-center align-middle items-center  ">
                        <div
                          className="bg-[#111213] hover:bg-[#252628] rounded-[1rem] border-2 border-[#ffffff1a] xxl:w-[38.75rem] xl:w-[38.75rem] sxl:w-[38.75rem] bxl:w-[38.75rem] lg:w-[38.75rem] sm:w-full md:w-full xd:w-full h-[5rem] flex justify-between p-4 cursor-pointer"
                          onClick={() => handleOnchainDetails(Floc)}
                        >
                          <div className=" flex flex-col">
                            <div className=" truncate text-[0.875rem] text-[#FFFFFF] font-[800]">
                              FLOC
                            </div>
                          </div>
                          <div className="text-[#FFFFFFCC] font-[800] flex">
                            {flocOnchain && flocOnchain.toLocaleString()}
                            <span className=" font-[400] pl-1 text-[#A5A5A6]">
                              txns
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="pt-3 flex flex-col justify-center align-middle items-center  ">
                        <div
                          className="bg-[#111213] hover:bg-[#252628] rounded-[1rem] border-2 border-[#ffffff1a] xxl:w-[38.75rem] xl:w-[38.75rem] sxl:w-[38.75rem] bxl:w-[38.75rem] lg:w-[38.75rem] sm:w-full md:w-full xd:w-full h-[5rem] flex justify-between p-4 cursor-pointer"
                          onClick={() => handleOnchainDetails(Eragon)}
                        >
                          <div className=" flex flex-col">
                            <div className=" truncate text-[0.875rem] text-[#FFFFFF] font-[800]">
                              ERAGON
                            </div>
                          </div>
                          <div className="text-[#FFFFFFCC] font-[800] flex">
                            {eragonOnchain && eragonOnchain.toLocaleString()}
                            <span className=" font-[400] pl-1 text-[#A5A5A6]">
                              txns
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    {data.length > 0 && (
                      <div className="xxl:w-1/2 xl:w-1/2 sxl:w-1/2 bxl:w-1/2 lg:w-full md:w-full sm:w-full xd:w-full bg-[#17181A] border-2 border-[#FFFFFF1A] rounded-[1rem] h-[37.125rem] ml-2 md:ml-0 sm:ml-0 xd:ml-0 xxl:mt-0 xl:mt-0 sxl:mt-0 bx:mt-0 lg:mt-4 md:mt-4 sm:mt-[-8rem] xd:mt-[-8rem]">
                        <div className="h-[3.5rem] border-b-2 border-[#ffffff1a] p-3">
                          <div className="text-[#FFFFFF] text-[0.875rem] font-[800]">
                            Top Function
                          </div>
                        </div>
                        <div className="h-[4.625rem] bg-[#111213] border-b-2 border-[#ffffff1a] flex justify-between p-[4%_4%]">
                          <div>
                            <div className="text-[1rem] text-[#FFFFFFCC] font-[800]">
                              Total Number of Functions Count
                            </div>
                          </div>
                          <div className="text-[1rem] text-[#FFFFFFCC] font-[800] ">
                            {overallTopFunctions &&
                              overallTopFunctions.toLocaleString()}
                          </div>
                        </div>
                        <div className="flex justify-center">
                          <div className="flex flex-col h-[27.5rem] overflow-y-scroll w-full">
                            <div className="text-[1rem] text-[#FFFFFFCC] px-3">
                              {data.map((item: any, index: any) => (
                                <div
                                  key={index}
                                  className=" xxl:w-[38.75rem] xl:w-[38.75rem] sxl:w-[38.75rem] bxl:w-[38.75rem] lg:w-[38.75rem] sm:w-full md:w-full xd:w-full rounded-[1rem] bg-[#111213] hover:bg-[#252628] gap-[1rem] border-[#ffffff1a] border-2 cursor-pointer px-[1.5rem] py-[1rem] mt-3"
                                >
                                  <div
                                    className="flex justify-between"
                                    onClick={() => handleDropmenu(index)}
                                  >
                                    <span className="font-[800]">
                                      {item.entry_function_name}
                                    </span>
                                    <img
                                      src={ArrownDown}
                                      alt="ArrownDown"
                                      className={`cursor-pointer transform ${
                                        openDropdown === index
                                          ? "rotate-180"
                                          : ""
                                      }`}
                                    />
                                  </div>
                                  {openDropdown === index && (
                                    <div className="text-[#A5A5A6] font-[400] p-3 leading-9">
                                      <div className=" flex justify-between">
                                        <span>Chingari Function Count</span>
                                        <span>
                                          {item.Chingari_function_count &&
                                            item.Chingari_function_count.toLocaleString()}
                                        </span>
                                      </div>
                                      <div className=" flex justify-between">
                                        <span>Chingari Percentage</span>
                                        <span>
                                          {item.Chingari_percentage &&
                                            item.Chingari_percentage.toLocaleString()}
                                        </span>
                                      </div>
                                      <div className=" flex justify-between">
                                        <span>Kana Spot Function Count</span>
                                        <span>
                                          {item.Kana_Spot_function_count &&
                                            item.Kana_Spot_function_count.toLocaleString()}
                                        </span>
                                      </div>
                                      <div className=" flex justify-between">
                                        <span>Kana Spot Percentage</span>
                                        <span>
                                          {item.Kana_Spot_percentage &&
                                            item.Kana_Spot_percentage.toLocaleString()}
                                        </span>
                                      </div>
                                      <div className=" flex justify-between">
                                        <span>Testing Function Count</span>
                                        <span>
                                          {item.Testing_function_count &&
                                            item.Testing_function_count.toLocaleString()}
                                        </span>
                                      </div>
                                      <div className=" flex justify-between">
                                        <span>Testing Percentage</span>
                                        <span>
                                          {item.Testing_percentage &&
                                            item.Testing_percentage.toLocaleString()}
                                        </span>
                                      </div>
                                      <div className=" flex justify-between">
                                        <span>Wapal Function Count</span>
                                        <span>
                                          {item.Wapal_function_count &&
                                            item.Wapal_function_count.toLocaleString()}
                                        </span>
                                      </div>
                                      <div className=" flex justify-between">
                                        <span>Wapal Percentage</span>
                                        <span>
                                          {item.Wapal_percentage &&
                                            item.Wapal_percentage.toLocaleString()}
                                        </span>
                                      </div>
                                      <div className=" flex justify-between">
                                        <span>Eragon Function Count</span>
                                        <span>
                                          {item.Eragon_function_count &&
                                            item.Eragon_function_count.toLocaleString()}
                                        </span>
                                      </div>
                                      <div className=" flex justify-between">
                                        <span>Eragon Percentage</span>
                                        <span>
                                          {item.Eragon_percentage &&
                                            item.Eragon_percentage.toLocaleString()}
                                        </span>
                                      </div>
                                      <div className=" flex justify-between">
                                        <span>Floc Function Count</span>
                                        <span>
                                          {item.Floc_function_count &&
                                            item.Floc_function_count.toLocaleString()}
                                        </span>
                                      </div>
                                      <div className=" flex justify-between">
                                        <span>Floc Percentage</span>
                                        <span>
                                          {item.Floc_percentage &&
                                            item.Floc_percentage.toLocaleString()}
                                        </span>
                                      </div>
                                      <div className=" flex justify-between">
                                        <span>Total Function Count</span>
                                        <span>
                                          {item.total_function_count &&
                                            item.total_function_count.toLocaleString()}
                                        </span>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              ))}
                            </div>
                            <div className="text-[1rem] text-[#FFFFFFCC]">
                              {data.map((item: any, index: any) => (
                                <div key={index} className="py-2">
                                  {item.percentage}
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;
